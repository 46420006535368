import { Steps, Button, message } from 'antd';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Input } from '../Input/Input'
import { LoadingOutlined } from "@ant-design/icons";
import photo from '../../assets/images/photo.png'
import { DatePicker, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DepMunActions } from '../../services/DepMun/DepMunSlice';
import { useForm, Controller } from "react-hook-form"
import moment from 'moment';
import { AreaActions } from '../../services/Area/AreaSlice';
import { EnterpriseActions } from '../../services/Enterprise/EnterpriseSlice';
import { LoginActions } from '../../services/Login/LoginSlices';
import SaveIcon from '../../assets/images/save.svg'
import { EditOutlined, SaveOutlined } from '@material-ui/icons';
import { SpecializedCenterActions } from '../../services/SpecializedCenter/SpecializedCenterSlice';
import { useUniqueSortedAreas } from '../../hooks/useUniqueSortedAreas';

export const FormSpecializedCenter = ({ edit, data, setOption }) => {
    const { Step } = Steps;
    const { Option } = Select;
    const [isSubmitting, setIsSubmitting] = useState(false)
    const dispatch = useDispatch()
    const [current, setCurrent] = React.useState(0);
    const { moduleWork, dependencySelected, locations } = useSelector(state => state.Area)
    const { enterprise, idUser, boss, dependencys } = useSelector(state => state.Login)
    const { headquarters } = useSelector(state => state.Enterprise)
    const { fullDataEquipment, loadingUpdateEquipment, loadingCreateEquipment } = useSelector(state => state.Equipment)
    const { loadingCreateSpecializedCenter } = useSelector(state => state.SpecializedCenter)
    console.log(data)
    useEffect(() => {
        dispatch(AreaActions.getLocations())
    }, [])

    /* useEffect(() => {
        if (EquipmentSpecializedCenterCreatedId) {
            setOption('Search')
            dispatch(EquipmentSpecializedCenterActions.setEquipmentSpecializedCenterCreatedId(false))
        }
    }, [EquipmentSpecializedCenterCreatedId]) */

    useEffect(() => {
        enterprise && dispatch(EnterpriseActions.getHeadquarters(enterprise.id))
    }, [enterprise])

    const defaultValues = useMemo(() => {
        return {
            "name": data ? data.name : "",
            "nit": data ? data.nit : "",
            "address": data ? data.address : "",
            "neighborhood": data ? data.neighborhood : "",
            "phone": data ? data.phone : "",
            "legalRepresentative": data ? data.legalRepresentative : "",
            "email": data ? data.email : "",
            "type": data ? data.type : "",
            "image": data ? data.image : "",
        }
    }, [fullDataEquipment]);

    const {
        register: registerSpecializedCenter,
        handleSubmit: handleSubmitSpecializedCenter,
        setValue: setValueSpecializedCenter,
        watch: watchSpecializedCenter,
        control: controlSpecializedCenter,
        formState: { errors: errorsSpecializedCenter },
        trigger: triggerSpecializedCenter,
    } = useForm({
        defaultValues
    })

    const [areaSelected, setAreaSelected] = useState(false)
    const [Dep, setDep] = useState(false);
    const [compInfo, setCompInfo] = useState([])
    const canvas = useRef(null)

    const [image, setImage] = useState(photo)

    const { departments, municipalitys } = useSelector(state => state.DepMun)

    useEffect(() => {
        Dep && dispatch(DepMunActions.getMunicipalitys(Dep))
    }, [Dep, dispatch])

    useEffect(() => {
        if (watchSpecializedCenter('dependency')) {
            dispatch(LoginActions.getBoss(watchSpecializedCenter('dependency')))
        }
    }, [watchSpecializedCenter('dependency')])

    const uploadImage = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));

        var ctx = canvas.current.getContext('2d')
        var img = new Image()

        canvas.current.width = 350
        canvas.current.height = 280

        img.onload = function () {
            ctx.drawImage(img, 0, 0, 350, 280)
        }
        var dataImg;
        setTimeout(function () { dataImg = canvas.current.toDataURL() }, 1000)
        img.src = URL.createObjectURL(e.target.files[0])

        setTimeout(function () { setValueSpecializedCenter("image", dataImg) }, 1000)
    };

    const areas = useUniqueSortedAreas();

    const specializedTypes = [
        "Taller", "Eléctrico", "Taller de pintura", "Estación de gasolina"
    ]

    return (
        <div className='FormSpecializedCenter'>
            <div className='row'>
                <div className='divDate'>
                    <p className='label'>Tipo de Centro Especializado</p>
                    <Controller
                        name="type"
                        rules={{
                            required: "El tipo de centro especializado es requerido"
                        }
                        }
                        control={controlSpecializedCenter}
                        render={({ field }) => (
                            <Select
                                {...field}
                                placeholder='Tipo de Centro Especializado'
                                value={watchSpecializedCenter('type')}
                                className="select"
                            >
                                {specializedTypes && specializedTypes.map((d, i) => (
                                    <Option key={i} value={d}>{d}</Option>
                                ))}
                            </Select>
                        )}
                    />
                    {errorsSpecializedCenter.type && <p className='errorMessage'>{errorsSpecializedCenter.type.message}</p>}
                </div>
                <Input
                    {...registerSpecializedCenter('name', {
                        validate: (value) => {
                            const errors = [];
                            if (!value || value === '') {
                                errors.push("El nombre es requerido");
                            }
                            return errors.length === 0 || errors.join(', ');
                        },
                    })}
                    label={"Nombre del Establecimiento Comercial"}
                    placeholder={"Digite el Nombre"}
                    width={'13vw'}
                    height={'2vw'}
                    labelstyle='inputLabelBordered'
                    fontSize={'1vw'}
                    labelMB={'-0.1vw'}
                    va={watchSpecializedCenter('name')}
                    setData={e => setValueSpecializedCenter('name', e)}
                    error={errorsSpecializedCenter.name && errorsSpecializedCenter.name.message}
                />
                <Input
                    {...registerSpecializedCenter('nit', {
                        validate: (value) => {
                            const errors = [];
                            if (!value || value === '') {
                                errors.push("El nit es requerido");
                            }
                            return errors.length === 0 || errors.join(', ');
                        },
                    })}
                    label={"Nit"}
                    placeholder={"Digite el Nit"}
                    width={'13vw'}
                    height={'2vw'}
                    labelstyle='inputLabelBordered'
                    fontSize={'1vw'}
                    labelMB={'0.8vw'}
                    va={watchSpecializedCenter('nit')}
                    setData={e => setValueSpecializedCenter('nit', e)}
                    error={errorsSpecializedCenter.nit && errorsSpecializedCenter.nit.message}
                />
                <Input
                    {...registerSpecializedCenter('address', {
                        validate: (value) => {
                            const errors = [];
                            if (!value || value === '') {
                                errors.push("La dirección es requerido");
                            }
                            return errors.length === 0 || errors.join(', ');
                        },
                    })}
                    label={"Dirección"}
                    width={'13vw'}
                    height={'2vw'}
                    labelstyle='inputLabelBordered'
                    fontSize={'1vw'}
                    labelMB={'0.8vw'}
                    va={watchSpecializedCenter('address')}
                    setData={e => setValueSpecializedCenter('address', e)}
                    error={errorsSpecializedCenter.address && errorsSpecializedCenter.address.message}
                />
            </div>

            <div className='row'>
                <Input
                    {...registerSpecializedCenter('neighborhood', {
                        validate: (value) => {
                            const errors = [];
                            if (!value || value === '') {
                                errors.push("El barrio es requerido");
                            }
                            return errors.length === 0 || errors.join(', ');
                        },
                    })}
                    label={"Barrio"}
                    width={'13vw'}
                    height={'2vw'}
                    labelstyle='inputLabelBordered'
                    fontSize={'1vw'}
                    labelMB={'-0.1vw'}
                    va={watchSpecializedCenter('neighborhood')}
                    setData={e => setValueSpecializedCenter('neighborhood', e)}
                    error={errorsSpecializedCenter.neighborhood && errorsSpecializedCenter.neighborhood.message}
                />
                <Input
                    {...registerSpecializedCenter('phone', {
                        validate: (value) => {
                            const errors = [];
                            if (!value || value === '') {
                                errors.push("El telefono es requerido");
                            }
                            return errors.length === 0 || errors.join(', ');
                        },
                    })}
                    label={"Telefono"}
                    placeholder={"Digite el Telefono"}
                    width={'13vw'}
                    height={'2vw'}
                    labelstyle='inputLabelBordered'
                    fontSize={'1vw'}
                    labelMB={'-0.1vw'}
                    va={watchSpecializedCenter('phone')}
                    setData={e => setValueSpecializedCenter('phone', e)}
                    error={errorsSpecializedCenter.phone && errorsSpecializedCenter.phone.message}
                />
                <Input
                    {...registerSpecializedCenter('legalRepresentative', {
                    })}
                    label={"Representante Legal"}
                    width={'13vw'}
                    height={'2vw'}
                    labelstyle='inputLabelBordered'
                    fontSize={'1vw'}
                    labelMB={'0vw'}
                    va={watchSpecializedCenter('legalRepresentative')}
                    setData={e => setValueSpecializedCenter('legalRepresentative', e)}
                    error={errorsSpecializedCenter.legalRepresentative && errorsSpecializedCenter.legalRepresentative.message}
                />
                <Input
                    {...registerSpecializedCenter('email', {
                        validate: (value) => {
                            const errors = [];
                            if (!value || value === '') {
                                errors.push("El correo es requerido");
                            }
                            return errors.length === 0 || errors.join(', ');
                        },
                    })}
                    label={"Correo Electrónico"}
                    width={'13vw'}
                    height={'2vw'}
                    labelstyle='inputLabelBordered'
                    fontSize={'1vw'}
                    labelMB={'0vw'}
                    va={watchSpecializedCenter('email')}
                    setData={e => setValueSpecializedCenter('email', e)}
                    error={errorsSpecializedCenter.email && errorsSpecializedCenter.email.message}
                />
            </div>

            <div className='row'>
                {!data && <Input
                    {...registerSpecializedCenter('contractNumber', {
                        validate: (value) => {
                            const errors = [];
                            if (!value || value === '') {
                                errors.push("El Numero de contrato es requerido");
                            }
                            return errors.length === 0 || errors.join(', ');
                        },
                    })}
                    label={"Numero de contrato"}
                    width={'13vw'}
                    height={'2vw'}
                    labelstyle='inputLabelBordered'
                    fontSize={'1vw'}
                    labelMB={'-0.1vw'}
                    va={watchSpecializedCenter('contractNumber')}
                    setData={e => setValueSpecializedCenter('contractNumber', e)}
                    error={errorsSpecializedCenter.contractNumber && errorsSpecializedCenter.contractNumber.message}
                />}
                {!data && <div className='divDate'>
                    <p className='label'>Fecha Inicio</p>
                    <DatePicker
                        value={watchSpecializedCenter("startDate")}
                        {...registerSpecializedCenter("startDate", {
                            validate: (value) => {
                                const errors = [];
                                if (!value || value === '') {
                                    errors.push("La fecha de inicio es requerida");
                                }
                                return errors.length === 0 || errors.join(', ');
                            },
                        })}
                        style={{ width: '13vw', height: '2vw' }}
                        onChange={d => setValueSpecializedCenter("startDate", d)}
                    />
                    {errorsSpecializedCenter.startDate && <p className='errorMessage'>{errorsSpecializedCenter.startDate.message}</p>}
                </div>}
                {!data && <div className='divDate'>
                    <p className='label'>Fecha Final</p>
                    <DatePicker
                        value={watchSpecializedCenter("endDate")}
                        {...registerSpecializedCenter("endDate", {
                            validate: (value) => {
                                const errors = [];
                                if (!value || value === '') {
                                    errors.push("La fecha final es requerida");
                                }
                                return errors.length === 0 || errors.join(', ');
                            },
                        })}
                        style={{ width: '13vw', height: '2vw' }}
                        onChange={d => setValueSpecializedCenter("endDate", d)}
                    />
                    {errorsSpecializedCenter.endDate && <p className='errorMessage'>{errorsSpecializedCenter.endDate.message}</p>}
                </div>}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <p className='label'>Foto o Logo del Establecimiento</p>
                    {!watchSpecializedCenter('image') ?
                        <input
                            {...registerSpecializedCenter('image', {
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("La imagen es requerida");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                }
                            })}
                            type="file"
                            accept="image/*"
                            className={!data ? 'inputPhoto' : 'inputPhotoEdit'}
                            onChange={(event) => {
                                const file = event.target.files[0];
                                const reader = new FileReader();
                                reader.addEventListener('load', () => {
                                    const base64 = reader.result;
                                    setValueSpecializedCenter('image', base64)
                                });
                                reader.readAsDataURL(file);
                            }}
                        />
                        :
                        <img src={watchSpecializedCenter("image")} className={!data ? 'inputPhoto' : 'inputPhotoEdit'} onClick={() => setValueSpecializedCenter('image', undefined)}></img>
                    }
                    {errorsSpecializedCenter.image && <p className='errorMessage'>{errorsSpecializedCenter.image.message}</p>}
                </div>
            </div>
            {!data && <p className='title'>Registrar Usuario de Ingreso a Zeusdesk.net</p>}
            <div className='row'>
                {!data && <Input
                    {...registerSpecializedCenter('nameUser', {
                        validate: (value) => {
                            const errors = [];
                            if (!value || value === '') {
                                errors.push("El Nombre es requerido");
                            }
                            return errors.length === 0 || errors.join(', ');
                        },
                    })}
                    label={"Nombre"}
                    width={'13vw'}
                    height={'2vw'}
                    labelstyle='inputLabelBordered'
                    fontSize={'1vw'}
                    labelMB={'-0.1vw'}
                    va={watchSpecializedCenter('nameUser')}
                    setData={e => setValueSpecializedCenter('nameUser', e)}
                    error={errorsSpecializedCenter.nameUser && errorsSpecializedCenter.nameUser.message}
                />}
                {!data && <Input
                    {...registerSpecializedCenter('emailUser', {
                        validate: (value) => {
                            const errors = [];
                            if (!value || value === '') {
                                errors.push("El Correo es requerido");
                            }
                            return errors.length === 0 || errors.join(', ');
                        },
                    })}
                    label={"Correo"}
                    width={'13vw'}
                    height={'2vw'}
                    labelstyle='inputLabelBordered'
                    fontSize={'1vw'}
                    labelMB={'-0.1vw'}
                    va={watchSpecializedCenter('emailUser')}
                    setData={e => setValueSpecializedCenter('emailUser', e)}
                    error={errorsSpecializedCenter.emailUser && errorsSpecializedCenter.emailUser.message}
                />}
                {!data && <Input
                    {...registerSpecializedCenter('password', {
                        validate: (value) => {
                            const errors = [];
                            if (!value || value === '') {
                                errors.push("El Contraseña es requerido");
                            }
                            return errors.length === 0 || errors.join(', ');
                        },
                    })}
                    label={"Contraseña"}
                    width={'13vw'}
                    height={'2vw'}
                    labelstyle='inputLabelBordered'
                    fontSize={'1vw'}
                    labelMB={'-0.1vw'}
                    type={'password'}
                    va={watchSpecializedCenter('password')}
                    setData={e => setValueSpecializedCenter('password', e)}
                    error={errorsSpecializedCenter.password && errorsSpecializedCenter.password.message}
                />}
                {!data && <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <p className='label'>Foto de la Firma</p>
                    {!watchSpecializedCenter('firm') ?
                        <input
                            {...registerSpecializedCenter('firm', {
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("La imagen es requerida");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                }
                            })}
                            type="file"
                            accept="image/*"
                            className='inputPhoto'
                            onChange={(event) => {
                                const file = event.target.files[0];
                                const reader = new FileReader();
                                reader.addEventListener('load', () => {
                                    const base64 = reader.result;
                                    setValueSpecializedCenter('firm', base64)
                                });
                                reader.readAsDataURL(file);
                            }}
                        />
                        :
                        <img src={watchSpecializedCenter("firm")} className='inputPhoto' onClick={() => setValueSpecializedCenter('firm', undefined)}></img>
                    }
                    {errorsSpecializedCenter.firm && <p className='errorMessage'>{errorsSpecializedCenter.firm.message}</p>}
                </div>}
            </div>
            {!data && <div className='row'>
                <Input
                    {...registerSpecializedCenter('position', {
                        validate: (value) => {
                            const errors = [];
                            if (!value || value === '') {
                                errors.push("El Cargo es requerido");
                            }
                            return errors.length === 0 || errors.join(', ');
                        },
                    })}
                    label={"Cargo"}
                    width={'13vw'}
                    height={'2vw'}
                    labelstyle='inputLabelBordered'
                    fontSize={'1vw'}
                    labelMB={'-0.1vw'}
                    va={watchSpecializedCenter('position')}
                    setData={e => setValueSpecializedCenter('position', e)}
                    error={errorsSpecializedCenter.position && errorsSpecializedCenter.position.message}
                />
            </div>}
            <div className='buttonSave' style={{ marginTop: Object.keys(errorsSpecializedCenter).length !== 0 && '-1vw' }} onClick={() => {
                handleSubmitSpecializedCenter((dataSubmit) => {
                    !loadingCreateSpecializedCenter ?
                        !data ?
                            dispatch(SpecializedCenterActions.createSpecializedCenter(dataSubmit)) :
                            dispatch(SpecializedCenterActions.updateSpecializedCenter({ "id": data.id, "body": dataSubmit })) :
                        message.loading("Espere un momento por favor")
                })()
            }}>
                {!data ? <SaveOutlined className='iconButton' /> : <EditOutlined className='iconButton' />}
                {loadingCreateSpecializedCenter ? <LoadingOutlined /> : !data ? "Guardar" : "Editar"}
            </div>
        </div>
    )
}