import { Steps, Button, message, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { LoadingOutlined, SearchOutlined, CloseCircleFilled } from "@ant-design/icons";
import photo from '../../assets/images/photo.png'
import { useDispatch, useSelector } from 'react-redux';
import { DepMunActions } from '../../services/DepMun/DepMunSlice';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';
import { useForm } from "react-hook-form"
import moment from 'moment';
import { AreaActions } from '../../services/Area/AreaSlice';
import { EnterpriseActions } from '../../services/Enterprise/EnterpriseSlice';
import { LoginActions } from '../../services/Login/LoginSlices';
import SaveIcon from '../../assets/images/save.svg'
import { EquipmentVehicleActions } from '../../services/EquipmentVehicle/EquipmentVehicleSlice';
import { ModalSearchEquipment } from '../ModalSearchEquipment/ModalSearchEquipment';
import { useStepsPowerPlant } from './StepsPowerPlant';
import { CaseReportPowerPlantActions } from '../../services/caseReportPowerPlant/caseReportPowerPlantSlice';
import { EquipmentCard } from '../EquipmentCard/EquipmentCard';
import { useStepsAirConditionerAndFridge } from './StepsAirConditionerAndFridge';
import { useUniqueSortedAreas } from '../../hooks/useUniqueSortedAreas';

export const FormServiceReportIndustrial = ({ edit, data, setOption }) => {
    const { Step } = Steps;
    const { Option } = Select;
    const [isSubmitting, setIsSubmitting] = useState(false)
    const dispatch = useDispatch()
    const [current, setCurrent] = React.useState(0);
    const { moduleWork, dependencySelected, locations } = useSelector(state => state.Area)
    const { enterprise, idUser, boss, dependencys } = useSelector(state => state.Login)
    const { headquarters } = useSelector(state => state.Enterprise)
    const { fullDataEquipment, loadingUpdateEquipment, loadingCreateEquipment, equipmentsSearched } = useSelector(state => state.Equipment)
    const { loadingCreateEquipmentVehicleF, EquipmentVehicleCreatedId } = useSelector(state => state.EquipmentVehicle)
    const { loadingCreateCaseReportPowerPlant, loadingAirAndFridgeReport } = useSelector(state => state.CaseReportPowerPlant)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleExitSearch, setVisibleExitSearch] = useState(false)
    const [equipSelected, setEquipSelected] = useState(false)

    useEffect(() => {
        dispatch(AreaActions.getLocations())
    }, [])

    useEffect(() => {
        if (EquipmentVehicleCreatedId) {
            setOption('Search')
            dispatch(EquipmentVehicleActions.setEquipmentVehicleCreatedId(false))
        }
    }, [EquipmentVehicleCreatedId])

    const onExitSearch = () => {
        setVisibleExitSearch(false)
        dispatch(EquipmentActions.setEquipmentsFiltered(false))
    }

    useEffect(() => {
        enterprise && dispatch(EnterpriseActions.getHeadquarters(enterprise.id))
    }, [enterprise])

    const {
        register: registerCreatePowerPlantServiceReport,
        handleSubmit: handleSubmitCreatePowerPlantServiceReport,
        setValue: setValueCreatePowerPlantServiceReport,
        watch: watchCreatePowerPlantServiceReport,
        control: controlCreatePowerPlantServiceReport,
        formState: { errors: errorsCreatePowerPlantServiceReport },
        trigger: triggerCreatePowerPlantServiceReport,
    } = useForm({
        defaultValues: {
            date: edit ? moment(data.date) : '',
            serviceType: edit ? data.serviceType : "",
            initialObservation: edit ? data.initialObservation : "",
            oilLevelStatus: edit ? data.oilLevelStatus : "",
            coolantLevelStatus: edit ? data.coolantLevelStatus : "",
            hosesStatus: edit ? data.hosesStatus : "",
            sealsStatus: edit ? data.sealsStatus : "",
            pipelinesStatus: edit ? data.pipelinesStatus : "",
            radiatorStatus: edit ? data.radiatorStatus : "",
            guardStatus: edit ? data.guardStatus : "",
            fanBeltsStatus: edit ? data.fanBeltsStatus : "",
            alternatorBeltsStatus: edit ? data.alternatorBeltsStatus : "",
            shockAbsorbersStatus: edit ? data.shockAbsorbersStatus : "",
            preheaterStatus: edit ? data.preheaterStatus : "",
            batteryStatus: edit ? data.batteryStatus : "",
            electrolyteLevelStatus: edit ? data.electrolyteLevelStatus : "",
            batteryVoltageStatus: edit ? data.batteryVoltageStatus : "",
            chargerStatus: edit ? data.chargerStatus : "",
            controlConnectionsStatus: edit ? data.controlConnectionsStatus : "",
            powerConnectionsStatus: edit ? data.powerConnectionsStatus : "",
            generatorStatus: edit ? data.generatorStatus : "",
            generalCleaningStatus: edit ? data.generalCleaningStatus : "",
            fuelLevelDescription: edit ? data.fuelLevelDescription : "",
            tankCapacityDescription: edit ? data.tankCapacityDescription : "",
            chargerVoltageDescription: edit ? data.chargerVoltageDescription : "",
            alternatorVoltageDescription: edit ? data.alternatorVoltageDescription : "",
            controlTypeDescription: edit ? data.controlTypeDescription : "",
            leaksDescription: edit ? data.leaksDescription : "",
            cabinetStatus: edit ? data.cabinetStatus : "",
            doorsStatus: edit ? data.doorsStatus : "",
            locksStatus: edit ? data.locksStatus : "",
            hingesStatus: edit ? data.hingesStatus : "",
            GECleaningStatus: edit ? data.GECleaningStatus : "",
            INDIPilotsStatus: edit ? data.INDIPilotsStatus : "",
            selectorsStatus: edit ? data.selectorsStatus : "",
            relaysStatus: edit ? data.relaysStatus : "",
            timersStatus: edit ? data.timersStatus : "",
            contactorsStatus: edit ? data.contactorsStatus : "",
            switchesStatus: edit ? data.switchesStatus : "",
            controlConnectionStatus: edit ? data.controlConnectionStatus : "",
            powerConnectionStatus: edit ? data.powerConnectionStatus : "",
            powerBarStatus: edit ? data.powerBarStatus : "",
            neutralBusbarStatus: edit ? data.neutralBusbarStatus : "",
            groundBusStatus: edit ? data.groundBusStatus : "",
            plcStatus: edit ? data.plcStatus : "",
            atsStatus: edit ? data.atsStatus : "",
            auxiliarySourcesStatus: edit ? data.auxiliarySourcesStatus : "",
            capacitorsStatus: edit ? data.capacitorsStatus : "",
            networkAnalyzersStatus: edit ? data.networkAnalyzersStatus : "",
            oilTemperatureValue: edit ? data.oilTemperatureValue : "",
            oilTemperatureUnit: edit ? data.oilTemperatureUnit : "",
            turboTemperatureValue: edit ? data.turboTemperatureValue : "",
            turboTemperatureUnit: edit ? data.turboTemperatureUnit : "",
            rpmValue: edit ? data.rpmValue : "",
            rpmUnit: edit ? data.rpmUnit : "",
            vacPhasesL1L1Description: edit ? data.vacPhasesL1L1Description : "",
            vacPhasesL2L2Description: edit ? data.vacPhasesL2L2Description : "",
            vacPhasesL3L3Description: edit ? data.vacPhasesL3L3Description : "",
            vacPhasesNL1NDescription: edit ? data.vacPhasesNL1NDescription : "",
            vacPhasesNL2NDescription: edit ? data.vacPhasesNL2NDescription : "",
            vacPhasesNL3NDescription: edit ? data.vacPhasesNL3NDescription : "",
            ampsL1Description: edit ? data.ampsL1Description : "",
            ampsL2Description: edit ? data.ampsL2Description : "",
            ampsL3Description: edit ? data.ampsL3Description : "",
            powerKVADescription: edit ? data.powerKVADescription : "",
            powerHzDescription: edit ? data.powerHzDescription : "",
            powerFPDescription: edit ? data.powerFPDescription : "",
            airFilterValue: edit ? data.airFilterValue : "",
            airFilterUnit: edit ? data.airFilterUnit : "",
            oilFilterValue: edit ? data.oilFilterValue : "",
            oilFilterUnit: edit ? data.oilFilterUnit : "",
            fuelFilterValue: edit ? data.fuelFilterValue : "",
            fuelFilterUnit: edit ? data.fuelFilterUnit : "",
            separatorFilterValue: edit ? data.separatorFilterValue : "",
            separatorFilterUnit: edit ? data.separatorFilterUnit : "",
            waterFilterValue: edit ? data.waterFilterValue : "",
            waterFilterUnit: edit ? data.waterFilterUnit : "",
            oilQuantityValue: edit ? data.oilQuantityValue : "",
            oilQuantityUnit: edit ? data.oilQuantityUnit : "",
            lowPressureDescription: edit ? data.lowPressureDescription : "",
            highTemperatureDescription: edit ? data.highTemperatureDescription : "",
            lowCoolantLevelDescription: edit ? data.lowCoolantLevelDescription : "",
            lowVoltageACDescription: edit ? data.lowVoltageACDescription : "",
            controlAtTheEndStatus: edit ? data.controlAtTheEndStatus : "",
            chargerAtTheEndStatus: edit ? data.chargerAtTheEndStatus : "",
            toteAtTheEndStatus: edit ? data.toteAtTheEndStatus : "",
            preheaterAtTheEndStatus: edit ? data.preheaterAtTheEndStatus : "",
            TDSDescription: edit ? data.TDSDescription : "",
            TDNEDescription: edit ? data.TDNEDescription : "",
            TDNPDescription: edit ? data.TDNPDescription : "",
            TDENDescription: edit ? data.TDENDescription : "",
            TDECDescription: edit ? data.TDECDescription : "",
            batteryVoltageAtTheEndDescription: edit ? data.batteryVoltageAtTheEndDescription : "",
            batteryVoltageDropAtTheEndDescription: edit ? data.batteryVoltageDropAtTheEndDescription : "",
            powerCablesDescription: edit ? data.powerCablesDescription : "",
            terminalsDescription: edit ? data.terminalsDescription : "",
            bodyOfContractorsDescription: edit ? data.bodyOfContractorsDescription : "",
            bodyOfSwitchesDescription: edit ? data.bodyOfSwitchesDescription : "",
            transformersDescription: edit ? data.transformersDescription : "",
            hottestSpotDescription: edit ? data.hottestSpotDescription : "",
            L1NDescription: edit ? data.L1NDescription : "",
            L2NDescription: edit ? data.L2NDescription : "",
            L3NDescription: edit ? data.L3NDescription : "",
            l1PowerDescription: edit ? data.l1PowerDescription : "",
            l2PowerDescription: edit ? data.l2PowerDescription : "",
            l3PowerDescription: edit ? data.l3PowerDescription : "",
            hzFrequencyDescription: edit ? data.hzFrequencyDescription : "",
            kwFrequencyDescription: edit ? data.kwFrequencyDescription : "",
            l1FrequencyDescription: edit ? data.l1FrequencyDescription : "",
            l2FrequencyDescription: edit ? data.l2FrequencyDescription : "",
            l3FrequencyDescription: edit ? data.l3FrequencyDescription : "",
            avgFrequencyDescription: edit ? data.avgFrequencyDescription : "",
            pfPFactorDescription: edit ? data.pfPFactorDescription : "",
            kwaPFactorDescription: edit ? data.kwaPFactorDescription : "",
            l1PFactorDescription: edit ? data.l1PFactorDescription : "",
            l2PFactorDescription: edit ? data.l2PFactorDescription : "",
            l3PFactorDescription: edit ? data.l3PFactorDescription : "",
            avgPFactorDescription: edit ? data.avgPFactorDescription : "",
            finalObservation: edit ? data.finalObservation : "",
        }
    })

    const {
        register: registerCreateAirAndFridgeServiceReport,
        handleSubmit: handleSubmitCreateAirAndFridgeServiceReport,
        setValue: setValueCreateAirAndFridgeServiceReport,
        watch: watchCreateAirAndFridgeServiceReport,
        control: controlCreateAirAndFridgeServiceReport,
        formState: { errors: errorsCreateAirAndFridgeServiceReport },
        trigger: triggerCreateAirAndFridgeServiceReport,
        reset: resetCreateAirAndFridgeServiceReport,
    } = useForm({
        defaultValues: {
            date: edit ? moment(data.date) : '',

        }
    })

    const StepsPowerPlant = useStepsPowerPlant(
        registerCreatePowerPlantServiceReport,
        handleSubmitCreatePowerPlantServiceReport,
        setValueCreatePowerPlantServiceReport,
        watchCreatePowerPlantServiceReport,
        controlCreatePowerPlantServiceReport,
        errorsCreatePowerPlantServiceReport,
        triggerCreatePowerPlantServiceReport,
        equipSelected,
        setEquipSelected,
    );

    const StepsAirConditionerAndFridge = useStepsAirConditionerAndFridge(
        registerCreateAirAndFridgeServiceReport,
        handleSubmitCreateAirAndFridgeServiceReport,
        setValueCreateAirAndFridgeServiceReport,
        watchCreateAirAndFridgeServiceReport,
        controlCreateAirAndFridgeServiceReport,
        errorsCreateAirAndFridgeServiceReport,
        triggerCreateAirAndFridgeServiceReport,
        equipSelected,
        setEquipSelected,
        data,
        resetCreateAirAndFridgeServiceReport
    );

    const [areaSelected, setAreaSelected] = useState(false)
    const [Dep, setDep] = useState(false);
    const [compInfo, setCompInfo] = useState([])
    const frontalCanvas = useRef(null)
    const backCanvas = useRef(null)
    const rightCanvas = useRef(null)
    const leftCanvas = useRef(null)
    const aboveCanvas = useRef(null)
    const transitLicenseCanvas = useRef(null)
    const soatCanvas = useRef(null)
    const policyCanvas = useRef(null)
    const mechaTechReviewCanvas = useRef(null)

    const [frontalImage, setFrontalImage] = useState(photo)
    const [backImage, setBackImage] = useState(photo)
    const [leftImage, setLeftImage] = useState(photo)
    const [rightImage, setRightImage] = useState(photo)
    const [aboveImage, setAboveImage] = useState(photo)
    const [transitLicenseImage, setTransitLicenseImage] = useState(photo)
    const [soatImage, setSoatImage] = useState(photo)
    const [policyImage, setPolicyImage] = useState(photo)
    const [mechaTechReviewImage, setMechaTechReviewImage] = useState(photo)

    const { departments, municipalitys } = useSelector(state => state.DepMun)

    const nextPowerPlant = async () => {
        const fieldsToValidate = {
            0: ["leaksDescription", "controlTypeDescription", "alternatorVoltageDescription", "chargerVoltageDescription",
                "tankCapacityDescription", "fuelLevelDescription",
                "generalCleaningStatus", "generatorStatus", "powerConnectionsStatus", "controlConnectionsStatus",
                "chargerStatus", "batteryVoltageStatus",
                "electrolyteLevelStatus", "batteryStatus", "preheaterStatus", "shockAbsorbersStatus", "alternatorBeltsStatus",
                "fanBeltsStatus", "guardStatus",
                "radiatorStatus", "pipelinesStatus", "sealsStatus", "hosesStatus", "coolantLevelStatus", "oilLevelStatus",
                "initialObservation", "serviceType",
                "date"],
            1: ['cabinetStatus', 'doorsStatus', 'locksStatus', 'hingesStatus', 'GECleaningStatus', 'INDIPilotsStatus',
                'selectorsStatus', 'relaysStatus', 'timersStatus', 'contactorsStatus', 'switchesStatus', 'controlConnectionStatus',
                'powerConnectionStatus', 'powerBarStatus', 'neutralBusbarStatus', 'groundBusStatus', 'plcStatus', 'atsStatus',
                'auxiliarySourcesStatus', 'capacitorsStatus', 'networkAnalyzersStatus'
            ],
            2: ['oilTemperatureValue', 'oilTemperatureUnit', 'turboTemperatureValue', 'turboTemperatureUnit',
                'rpmValue', 'rpmUnit', 'vacPhasesL1L1Description', 'vacPhasesL2L2Description', 'vacPhasesL3L3Description',
                'vacPhasesNL1NDescription', 'vacPhasesNL2NDescription', 'vacPhasesNL3NDescription', 'ampsL1Description',
                'ampsL2Description', 'ampsL3Description', 'powerKVADescription', 'powerHzDescription', 'powerFPDescription',
                'airFilterValue', 'airFilterUnit', 'oilFilterValue', 'oilFilterUnit', 'fuelFilterValue', 'fuelFilterUnit',
                'separatorFilterValue', 'separatorFilterUnit', 'waterFilterValue', 'waterFilterUnit', 'oilQuantityValue',
                'oilQuantityUnit', "lowPressureDescription", "highTemperatureDescription", "lowCoolantLevelDescription",
                "lowVoltageACDescription", "controlAtTheEndStatus", "chargerAtTheEndStatus", "toteAtTheEndStatus",
                "preheaterAtTheEndStatus"],
            3: ["TDSDescription", "TDNEDescription", "TDNPDescription", "TDENDescription", "TDECDescription",
                "batteryVoltageAtTheEndDescription", "batteryVoltageDropAtTheEndDescription", "powerCablesDescription", "terminalsDescription",
                "bodyOfContractorsDescription", "bodyOfSwitchesDescription", "transformersDescription", "hottestSpotDescription",
                "L1NValue", "L1NUnit", "L2NValue", "L2NUnit", "L3NValue", "L3NUnit", "l1PowerDescription", "l2PowerDescription", "l3PowerDescription",
                "hzFrequencyDescription", "kwFrequencyDescription", "l1FrequencyDescription", "l2FrequencyDescription",
                "l3FrequencyDescription", "avgFrequencyDescription", "pfPFactorDescription", "kwaPFactorDescription",
                "l1PFactorDescription", "l2PFactorDescription", "l3PFactorDescription", "avgPFactorDescription", "finalObservation"]
        };

        const isValid = await triggerCreatePowerPlantServiceReport(fieldsToValidate[current]);

        if (isValid) {
            setCurrent(current + 1)
            window.scrollTo(0, 0)
        } else {
            message.warn("Falta")
        }
    };

    const nextAirAndFridge = async () => {
        const fieldsToValidate = {
            0: ["date", "serviceType"],
        };

        const isValid = await triggerCreateAirAndFridgeServiceReport(fieldsToValidate[current]);

        if (isValid) {
            setCurrent(current + 1)
            window.scrollTo(0, 0)
        } else {
            message.warn("Falta")
        }
    };

    const prev = () => {
        setCurrent(current - 1);
        window.scrollTo(0, 0)
    };

    useEffect(() => {
        Dep && dispatch(DepMunActions.getMunicipalitys(Dep))
    }, [Dep, dispatch])

    useEffect(() => {
        if (watchCreatePowerPlantServiceReport('dependency')) {
            dispatch(LoginActions.getBoss(watchCreatePowerPlantServiceReport('dependency')))
        }
    }, [watchCreatePowerPlantServiceReport('dependency')])

    const uploadImage = (e, imageType) => {
        const canvass = {
            "frontalImage": frontalCanvas,
            "backImage": backCanvas,
            "leftImage": leftCanvas,
            "rightImage": rightCanvas,
            "aboveImage": aboveCanvas,
            "transitLicenseImage": transitLicenseCanvas,
            "SOATImage": soatCanvas,
            "policyImage": policyCanvas,
            "mechaTechReviewImage": mechaTechReviewCanvas
        }
        const setState = {
            "frontalImage": setFrontalImage,
            "backImage": setBackImage,
            "leftImage": setLeftImage,
            "rightImage": setRightImage,
            "aboveImage": setAboveImage,
            "transitLicenseImage": setTransitLicenseImage,
            "SOATImage": setSoatImage,
            "policyImage": setPolicyImage,
            "mechaTechReviewImage": setMechaTechReviewImage
        }

        const setImage = setState[imageType];
        const canvas = canvass[imageType];

        setImage(URL.createObjectURL(e.target.files[0]));

        var ctx = canvas.current.getContext('2d')
        var img = new Image()

        canvas.current.width = 350
        canvas.current.height = 280

        img.onload = function () {
            ctx.drawImage(img, 0, 0, 350, 280)
        }
        var dataImg;
        setTimeout(function () { dataImg = canvas.current.toDataURL() }, 1000)
        img.src = URL.createObjectURL(e.target.files[0])

        setTimeout(function () { setValueCreatePowerPlantServiceReport(imageType, dataImg) }, 1000)
    };

    const areas = useUniqueSortedAreas();

    const Stepss = {
        "Planta eléctrica": StepsPowerPlant,
        "Nevera para conservación biológica": StepsAirConditionerAndFridge,
        "Congelador": StepsAirConditionerAndFridge,
        "Aire acondicionado": StepsAirConditionerAndFridge,
    }

    const Nextt = {
        "Planta eléctrica": nextPowerPlant,
        "Nevera para conservación biológica": nextAirAndFridge,
        "Congelador": nextAirAndFridge,
        "Aire acondicionado": nextAirAndFridge,
    }

    const onFinish = {
        "Planta eléctrica": handleSubmitCreatePowerPlantServiceReport((dataSubmit) => {
            dataSubmit.userCreator = idUser
            dataSubmit.moduleWork = moduleWork[0].moduleWork.id;
            dataSubmit.equipSelected = equipSelected.id;
            dispatch(CaseReportPowerPlantActions.createCaseReportPowerPlant(dataSubmit));
        }),
        "Nevera para conservación biológica": handleSubmitCreateAirAndFridgeServiceReport((dataSubmit) => {
            function updateValues(obj) {
                Object.keys(obj).forEach(key => {
                    if (Array.isArray(obj[key]) && obj[key].includes('Marcar Todo')) {
                        obj[key] = true;
                    } else if (Array.isArray(obj[key]) && obj[key].length === 0) {
                        obj[key] = false;
                    }
                });
            }

            updateValues(dataSubmit);
            dataSubmit.userCreator = idUser
            dataSubmit.moduleWork = moduleWork[0].moduleWork.id;
            dataSubmit.equipSelected = equipSelected.id;
            dispatch(CaseReportPowerPlantActions.createAirAndFridgeReport(dataSubmit));
        }),
        "Congelador": handleSubmitCreateAirAndFridgeServiceReport((dataSubmit) => {
            function updateValues(obj) {
                Object.keys(obj).forEach(key => {
                    if (Array.isArray(obj[key]) && obj[key].includes('Marcar Todo')) {
                        obj[key] = true;
                    } else if (Array.isArray(obj[key]) && obj[key].length === 0) {
                        obj[key] = false;
                    }
                });
            }

            updateValues(dataSubmit);
            dataSubmit.userCreator = idUser
            dataSubmit.moduleWork = moduleWork[0].moduleWork.id;
            dataSubmit.equipSelected = equipSelected.id;
            dispatch(CaseReportPowerPlantActions.createAirAndFridgeReport(dataSubmit));
        }),
        "Aire acondicionado": handleSubmitCreateAirAndFridgeServiceReport((dataSubmit) => {
            function updateValues(obj) {
                Object.keys(obj).forEach(key => {
                    if (Array.isArray(obj[key]) && obj[key].includes('Marcar Todo')) {
                        obj[key] = true;
                    } else if (Array.isArray(obj[key]) && obj[key].length === 0) {
                        obj[key] = false;
                    }
                });
            }

            updateValues(dataSubmit);
            dataSubmit.userCreator = idUser
            dataSubmit.moduleWork = moduleWork[0].moduleWork.id;
            dataSubmit.equipSelected = equipSelected.id;
            dispatch(CaseReportPowerPlantActions.createAirAndFridgeReport(dataSubmit));
        }),
    }

    return (
        <div className='FormServiceReportIndustrial'>
            <p className='titleSection'>Seleccione equipo</p>
            <div className='divSearchEquips'>
                <Button className="Selected" onClick={() => {
                    setVisibleModal(true)
                    dispatch(EquipmentActions.setLoading(false))
                }}> <SearchOutlined className="plus" />  Buscar</Button>
                {visibleExitSearch && <CloseCircleFilled className="closeIcon" onClick={() => onExitSearch()} />}
            </div>
            <div className='div-equipment' style={{ display: 'flex', flexWrap: 'wrap' }}>
                {equipmentsSearched && !equipSelected && equipmentsSearched.map((d, i) =>
                (
                    <EquipmentCard
                        onChange={() => setEquipSelected({
                            "name": d.name,
                            "brand": d.industrialEquipment.brand,
                            "model": d.industrialEquipment.modell,
                            "serie": d.industrialEquipment.serie,
                            "plate": d.licensePlate,
                            "location": d.location.name,
                            "moduleWork": d.moduleWork.id,
                            "state": d.state,
                            "id": d.id
                        })}
                        key={i}
                        name={d.name}
                        brand={d.industrialEquipment.brand}
                        model={d.industrialEquipment.modell}
                        serie={d.industrialEquipment.serie}
                        plate={d.licensePlate}
                        location={d.location.name}
                        moduleWork={d.moduleWork.id}
                        state={d.state}
                        id={d.id}
                    >
                    </EquipmentCard>
                ))

                }
            </div>
            <Steps current={current} >
                {Stepss[equipSelected.name] && Stepss[equipSelected.name].map(item => (
                    <Step key={item.title} title={item.title} icon={item.icon} />
                ))}
            </Steps>
            <div className="steps-content" >
                {Stepss[equipSelected.name] && Stepss[equipSelected.name][current].content}
            </div>

            {Stepss[equipSelected.name] &&
                <div className="steps-action" >
                    {current > 0 && (
                        <Button style={{ margin: '0 8px', backgroundColor: '#C4C4C4', borderRadius: '10px', width: '12vw', height: '3vw', fontSize: '1.3vw' }} onClick={() => prev()}>
                            Atras
                        </Button>
                    )}
                    {current < Stepss[equipSelected.name].length - 1 && (
                        <Button type="primary" style={{ backgroundColor: '#4E8E30', borderRadius: '10px', width: '12vw', height: '3vw', fontSize: '1.3vw' }} onClick={() => Nextt[equipSelected.name]()}>
                            Continuar
                        </Button>
                    )}
                    {current === Stepss[equipSelected.name].length - 1 && (
                        <Button type="primary" style={{ backgroundColor: '#4E8E30', borderRadius: '10px', width: '12vw', height: '3vw', fontSize: '1.3vw' }}
                            onClick={() => {
                                onFinish[equipSelected.name]();
                            }
                            }
                        >
                            <img src={SaveIcon} alt='inventoryIcon' className='icon' style={{ marginRight: '0.5vw' }}></img>
                            {(loadingCreateCaseReportPowerPlant || loadingAirAndFridgeReport) ? <LoadingOutlined /> : "Hecho"}
                        </Button>
                    )}
                </div>}
            <ModalSearchEquipment visibleModal={visibleModal} setVisibleModal={setVisibleModal} />

        </div >
    )
}