import { useMemo } from "react";
import { useSelector } from "react-redux";
import { store } from "../store/Store";

export const useUniqueSortedAreas = () => {
    const { dependencys } = useSelector(state => state.Login);

    return useMemo(() => {
        if (!dependencys) return [];

        return [...new Map(dependencys.map(item => [item.area.id, item.area])).values()]
            .sort((a, b) => a.name.localeCompare(b.name));
    }, [dependencys]);
};
