import { useEffect, useState } from "react"
import React from "react";
/* import { Input } from '../../../components/Input/Input'
 */import { FormServiceReport } from "../../../components/FormServiceReport/FormServiceReport";
import { Button, Modal, Select, DatePicker, message, Radio, Card } from 'antd'
/* import { MenuItem, Select } from "@material-ui/core"
 */import { PlusOutlined, SearchOutlined, LoadingOutlined } from "@ant-design/icons";
/* import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl'; */
import { useDispatch, useSelector } from "react-redux";
import { AreaActions } from "../../../services/Area/AreaSlice";
import { EquipmentActions } from "../../../services/Equipment/EquipmentSlice";
import { CaseReportActions } from "../../../services/caseReport/CaseReportSlice";
import { Input } from "../../../components/Input/Input";
import { CaseReport } from "../../../components/CaseReport/CaseReport";
import { EquipmentCard } from "../../../components/EquipmentCard/EquipmentCard";
import { ModalSearchEquipment } from "../../../components/ModalSearchEquipment/ModalSearchEquipment";
import { FormServiceReportSystem } from "../../../components/FormServiceReportSystem/FormServiceReportSystem";
import { CaseReportSystem } from "../../../components/CaseReportSystem/CaseReportSystem";
import { format } from 'date-fns';
import { CalibrationCertificate } from "../../../components/CalibrationCertificate/CalibrationCertificate";
import { useUniqueSortedAreas } from "../../../hooks/useUniqueSortedAreas";


export const Certificates = () => {

    const [edit, setEdit] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModal1, setVisibleModal1] = useState(false)
    const [visibleModalUploPDF, setVisibleModalUploPDF] = useState(false)
    const [visibleModal4, setVisibleModal4] = useState(false)
    const [visiblePdfCert, setVisiblePdfCert] = useState(false)
    const [equipData, setEquipData] = useState()
    const [equipToUpload, setEquipToUpload] = useState()
    const { RangePicker } = DatePicker;
    const { Option } = Select
    /*  const [group, setGroup] = useState('Grupo')
     const [type, setType] = useState('Tipo') */
    const dispatch = useDispatch()
/*     const { areas, equipmentTypes } = useSelector(state => state.Area)
 */    const { roleUser } = useSelector(state => state.Login)

    const { loadingPDF, loadingCaseReport, loadingFullDataCaseReport, notFirmedBySandra, option, optionCert, loadingCreateCalibrationCertificate, getCalibrationCertificate, loadingGetCalibrationCertificate, pdfFromCalibrationCertificate, lastCalibrationCertificateByEquipByModuleWork, loadingLastCalibrationCertificateByEquipByModuleWork } = useSelector(state => state.CaseReport)
    const { loadingEquipment, locations, states, equipmentsSearched, equipsWithCaseReports, loadingEquipsWithCaseReport, quantityEquipsWithCaseReports, isFiltered } = useSelector(state => state.Equipment)
    const [areaSelected, setAreaSelected] = useState(false)
    const { moduleWork } = useSelector(state => state.Area)
    const { idUser, dependencys } = useSelector(state => state.Login)

    const areas = useUniqueSortedAreas();

    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageFiltered, setCurrentPageFiltered] = useState(0);

    const [dependencySelectedToFilter, setDependencySelectedToFilter] = useState(false)
    const [nameEquipSelectedToFilter, setNameEquipSelectedToFilter] = useState(false)
    const [stateSelectedToFilter, setStateSelectedToFilter] = useState(false)
    const [locationSelectedToFilter, setLocationSelectedToFilter] = useState(false)
    const [dateSelectedToFilter, setDateSelectedToFilter] = useState(false)
    const [licensePlateSelectedToFilter, setLicensePlateSelectedToFilter] = useState(false)

    useEffect(() => {
        /*REPLACING BY EQUIPS dispatch(CaseReportActions.getCaseReports({ "area": area, "take": 8, "page": currentPage })) */
        moduleWork && optionCert === 'UploadCert' && !isFiltered && dispatch(EquipmentActions.search({
            "take": 8,
            "page": 0,
            "moduleWork": moduleWork[0].moduleWork.id,
        }))
        moduleWork && optionCert === 'SearchCert' && !isFiltered && dispatch(CaseReportActions.getLastCalibrationCertificateByEquipByModuleWork({ "moduleWork": moduleWork[0].moduleWork.id, "take": 8, "page": currentPage }))
    }, [currentPage, moduleWork, dispatch, optionCert])

    useEffect(() => {
        isFiltered && dispatch(CaseReportActions.getLastCalibrationCertificateByEquipByModuleWork({ "dependency": dependencySelectedToFilter, "name": nameEquipSelectedToFilter, "state": stateSelectedToFilter, "location": locationSelectedToFilter, "date": dateSelectedToFilter, take: 8, page: currentPageFiltered, moduleWork: moduleWork[0].moduleWork.id, licensePlate: licensePlateSelectedToFilter }))
    }, [currentPageFiltered, dispatch])

    useEffect(() => {
        dispatch(EquipmentActions.getLocations())
        dispatch(EquipmentActions.getStates())
    }, [dispatch])

    const buttons = (t) => {
        if (moduleWork) {
            t !== "SearchModal" && dispatch(CaseReportActions.setOptionCert(t))
            t !== "SearchModal" && setEdit(false)
            t === "SearchModal" && setVisibleModal(true)
            setCurrentPage(0)
            dispatch(EquipmentActions.setIsFiltered(false))
        } else {
            message.warning("Seleccione un modulo de trabajo")
        }

    }

    const onSearch = (nameEquip, state, location, dependency, date, licensePlate) => {
        setVisibleModal(false)
        setVisibleModal1(false)
        dispatch(EquipmentActions.setIsFiltered(true))
        dispatch(CaseReportActions.getLastCalibrationCertificateByEquipByModuleWork({ "dependency": dependency, "name": nameEquip, "state": state, "location": location, "date": date, take: 8, page: currentPageFiltered, moduleWork: moduleWork[0].moduleWork.id, licensePlate: licensePlate }))
        setDependencySelectedToFilter(dependency)
        setNameEquipSelectedToFilter(nameEquip)
        setStateSelectedToFilter(state)
        setLocationSelectedToFilter(location)
        setDateSelectedToFilter(date)
        setLicensePlateSelectedToFilter(licensePlate)
    }

    const ModalUploPDF = () => {
        const [file, setFile] = useState(false)
        const [fileName, setFileName] = useState(false)
        const [date, setDate] = useState(false)

        const fileToBase64 = (file, cb) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = function () {
                cb(null, reader.result)
            }
            reader.onerror = function (error) {
                cb(error, null)
            }
        }

        const post = () => {
            if (!file || !date) {
                message.warning("Selecciona un archivo y una fecha")
            } else {
                const data = {
                    "date": date,
                    "pdf": file,
                    "dbState": true,
                    "equipment": equipToUpload.id,
                    "userCreator": idUser
                }
                if (equipToUpload.id !== undefined) {
                    const actions = {
                        "Upload": () => dispatch(CaseReportActions.createCaseReportPDF(data)),
                        "UploadTimeline": () => dispatch(CaseReportActions.createTimelinePDF(data)),
                        "UploadCert": () => dispatch(CaseReportActions.createCalibrationCertificate(data))
                    };

                    const action = actions[optionCert];

                    if (action) {
                        action();
                    } else {
                        message.error("Opción no reconocida");
                    }
                } else {
                    message.error("Error al elegir equipo");
                }
            }
        }

        return (
            <Modal className="ModalUploPDF" visible={visibleModalUploPDF} footer={null} onCancel={() => setVisibleModalUploPDF(false)}>
                <div className="title">Seleccione para {optionCert === "Upload" ? "reporte" : "certificado"}</div>
                <div className="labelDiv">
                    <p className="label">Fecha</p>
                </div>
                <DatePicker className="datepicker" onChange={setDate}></DatePicker>
                <div className="subirarchivos">Subir archivos</div>
                <hr></hr>
                <div className="upload-area">
                    {fileName && <p className="filename">{fileName.name}</p>}
                    <input type="file" name="filetobase64" onChange={event => {
                        const file = event.target.files[0];
                        if (file) {
                            setFile(file);
                        } else {
                            message.error('Error al obtener archivo')
                        }
                    }} accept="application/pdf" />
                </div>
                <div className="buttons">
                    {/* <div className="button b1">Descartar</div> */}
                    {loadingPDF ?
                        <div className="button b2"><LoadingOutlined /> </div>
                        :
                        <div className="button b2" onClick={() => post()}> Guardar</div>
                    }
                </div>

            </Modal >
        )
    }

    const ModalSearch = () => {
        const [nameEquip, setNameEquip] = useState(false)
        const [location, setLocation] = useState(false)
        const [state, setState] = useState(false)
        const [date, setDate] = useState(false)
        const [areaSelected, setAreaSelected] = useState(false)
        const [dependencySelected, setDependencySelected] = useState(false)
        const [licensePlate, setLicensePlate] = useState(false)

        return (
            <Modal width={'44vw'} className='ModalSearchEquipment' visible={visibleModal} footer={null} onCancel={() => setVisibleModal(false)}>
                <p className="titleModal">Busqueda</p>
                <Input va={nameEquip} setData={setNameEquip} label={"Equipo"} width={"35vw"}></Input>
                <div className="selectsDependency">
                    <p className="label" style={{ marginTop: '-1vw', marginBottom: '0vw' }}>Area</p>
                    <Select
                        className="select"
                        placeholder='Area'
                        onChange={e => {
                            setAreaSelected(e)
                        }}
                    >
                        {dependencys && areas.map(d => (
                            <Option key={d.id} value={d.id} >{d.name}</Option>
                        ))}
                    </Select>
                    <p className="label" style={{ marginTop: '1vw', marginBottom: '0vw' }}>Dependencia</p>
                    {areaSelected &&

                        <Select
                            className="select"
                            placeholder='Dependencia'
                            onChange={e => setDependencySelected(e)}
                        >
                            {areaSelected && dependencys.filter(item => item.area.id === areaSelected).map(d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))}
                        </Select>}
                    <p className="label">Estado</p>
                    <Select
                        className="select"
                        showSearch
                        placeholder="Estado"
                        optionFilterProp="children"
                        onChange={setState}
                        value={state}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {states && states.map(d => (
                            <Option key={d.state} value={d.state}>{d.state}</Option>
                        ))}
                    </Select>
                    <Input va={licensePlate} setData={setLicensePlate} label={"Placa de equipo"} width={"35vw"} mt={"2vw"}></Input>
                </div>

                {/* <div>
                    <p className="label">Ubicación</p>
                    <Select
                        className="select"
                        showSearch
                        value={location}
                        placeholder="Ubicación"
                        optionFilterProp="children"
                        onChange={setLocation}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {locations && locations.map(d => (
                            <Option key={d} value={d.location}>{d.location}</Option>
                        ))}
                    </Select>
                </div> */}
                <div className="inputDiv a">
                    <p className="label e">Fecha Desde Hasta</p>
                </div>
                <RangePicker
                    className="rangepicker"
                    onChange={dates => {
                        const formattedDates = dates.map(date => date.format("YYYY-MM-DD"));
                        setDate(formattedDates);
                    }}
                />
                <div className="divButton">
                    {!loadingFullDataCaseReport ?
                        <Button className="button" onClick={() => onSearch(nameEquip, state, location, dependencySelected, date, licensePlate)}><SearchOutlined className="plus" /> Buscar</Button>
                        :
                        <Button className="button" ><LoadingOutlined className="plus" /> Buscar</Button>
                    }
                </div>
            </Modal >
        )
    }

    const pageSize = 8
    const totalPages = Math.ceil(lastCalibrationCertificateByEquipByModuleWork[1] / pageSize);

    const onSides = (n) => {
        if (isFiltered) {
            if (currentPageFiltered < totalPages - 1 & n === 1) {
                setCurrentPageFiltered(prev => prev + n)
            }
            if (currentPageFiltered > 0 & n === -1) {
                setCurrentPageFiltered(prev => prev + n)
            }
        } else {
            if (currentPage < totalPages - 1 & n === 1) {
                setCurrentPage(prev => prev + n)
            }
            if (currentPage > 0 & n === -1) {
                setCurrentPage(prev => prev + n)
            }
        }
    }

    const generateNButtons = (N, onClick, currentPage, totalPages) =>
        [...Array(N).keys()].map(i => (
            currentPage === i ? <div className='radiobuttonSelected'
                key={`Button_${i}`}
                onClick={() => onClick(i)}
            >
                {i + 1}
            </div>
                :
                Math.abs(i - currentPage) <= 2 ?
                    <div className='radiobutton'
                        key={`Button_${i}`}
                        onClick={() => onClick(i)}
                    >
                        {i + 1}
                    </div>
                    :
                    i === totalPages - 1 ?
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            {i + 1}
                        </div>
                        :
                        Math.abs(i - currentPage) === 3 &&
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            ...
                        </div>
        ))

    return (
        <div className='certificatesDiv'>
            <div className='titleDiv'><p className='inventoryText'>Certificados</p></div>
            <div className='buttonsSection'>
                {moduleWork && moduleWork[0].moduleWork.id === 1 && <Button className="buttonUploPDF" onClick={() => buttons("UploadCert")}>{!loadingCreateCalibrationCertificate ? "Cert. Calibración" : <LoadingOutlined className='plus' />}</Button>}
                {moduleWork && moduleWork[0].moduleWork.id === 1 &&
                    <Button
                        style={{ marginRight: '1vw' }}
                        className="Unselected"
                        onClick={() => {
                            optionCert === 'SearchCert' ? buttons('SearchModal') :
                                buttons('SearchCert')
                        }}>
                        {!loadingLastCalibrationCertificateByEquipByModuleWork ? <SearchOutlined className="plus" /> : <LoadingOutlined className='plus' />}
                        Buscar Cert. Calibración
                    </Button>
                }
                {/* {option === 'Search' ? <div className="buttons">
                    <Button className="Unselected add" onClick={() => buttons('Add')}>{!loadingEquipment ? <PlusOutlined className="plus" /> : <LoadingOutlined className='plus' />} Añadir</Button>
                    <Button className="Selected" onClick={() => {
                        buttons('SearchModal')
                    }}>{!loadingEquipsWithCaseReport ? <SearchOutlined className="plus" /> : <LoadingOutlined className='plus' />} Buscar</Button>
                </div>
                    :
                    option === 'Add' ? <div className="buttons">
                        <Button className="Selected add" onClick={() => buttons('Add')}>{!loadingEquipment ? <PlusOutlined className="plus" /> : <LoadingOutlined className='plus' />} Añadir</Button>
                        <Button className="Unselected" onClick={() => buttons('Search')}>{!loadingCaseReport ? <SearchOutlined className="plus" /> : <LoadingOutlined className='plus' />} Buscar</Button>
                    </div> :
                        <div className="buttons">
                            <Button className="Unselected add" onClick={() => buttons('Add')}>{!loadingEquipment ? <PlusOutlined className="plus" /> : <LoadingOutlined className='plus' />} Añadir</Button>
                            <Button className="Unselected" onClick={() => buttons('Search')}>{!loadingCaseReport ? <SearchOutlined className="plus" /> : <LoadingOutlined className='plus' />} Buscar</Button>
                        </div>} */}
            </div>

            {optionCert === 'Add' &&
                <div className='body'>
                    <div className='form'>
                        {
                            (() => {
                                switch (moduleWork[0].moduleWork.id) {
                                    case 1:
                                        return <FormServiceReport />;
                                    case 2:
                                        return <FormServiceReportSystem />;
                                }
                            })()
                        }
                    </div>
                </div>
            }
            {edit &&
                <div className='body'>
                    <div className='form'><FormServiceReport edit={edit} data={equipData}></FormServiceReport></div>
                </div>}

            {optionCert === 'SearchCert' && lastCalibrationCertificateByEquipByModuleWork && !edit &&
                <div>
                    <div className="titleItems" style={{ display: 'flex', justifyContent: 'space-between', width: '95%', marginTop: '1vw' }}>
                        <p>Imagen:</p>
                        <p>Nombre:</p>
                        <p>Marca:</p>
                        <p>Modelo:</p>
                        <p>Serie:</p>
                        <p>Placa:</p>
                        <p>Ubicación:</p>
                        <p>Estado:</p>
                        <p>Fecha:</p>
                    </div>

                    {lastCalibrationCertificateByEquipByModuleWork && lastCalibrationCertificateByEquipByModuleWork[0].map((d, index) => (
                        <CalibrationCertificate
                            key={d.id}
                            data={d}
                            setEdit={setEdit}
                            setEquipData={setEquipData}
                        />
                    ))}

                    {!isFiltered ?
                        <div className='showingText'>
                            Mostrando {((currentPage) * pageSize) + 1} - {Math.min((currentPage + 1) * pageSize, quantityEquipsWithCaseReports)} de {quantityEquipsWithCaseReports} elementos
                        </div>
                        :
                        <div className='showingText'>
                            Mostrando {((currentPageFiltered) * pageSize) + 1} - {Math.min((currentPageFiltered + 1) * pageSize, quantityEquipsWithCaseReports)} de {quantityEquipsWithCaseReports} elementos
                        </div>
                    }
                    <div className='divButtonsPages'>
                        <div className='sidesButtons' onClick={() => onSides(-1)}>Anterior</div>
                        {!isFiltered ?
                            <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                                {generateNButtons(totalPages, setCurrentPage, currentPage, totalPages)}
                            </Radio.Group>
                            :
                            <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                                {generateNButtons(totalPages, setCurrentPageFiltered, currentPageFiltered, totalPages)}
                            </Radio.Group>
                        }
                        <div className='sidesButtons' onClick={() => onSides(1)}>Siguiente</div>
                    </div>
                </div>
            }
            {optionCert === "Upload" | optionCert === "UploadTimeline" | optionCert === 'UploadCert' &&
                <>
                    <div className='divSearchEquips'>
                        <Button className="Selected" onClick={() => setVisibleModal1(true)}> <SearchOutlined className="plus" />  Buscar</Button>
                    </div>
                    <div className="div">
                        {equipmentsSearched && equipmentsSearched.map((d, i) => (
                            <EquipmentCard
                                key={i}
                                img={d.biomedicEquipment.image}
                                name={d.name}
                                brand={d.biomedicEquipment.brand}
                                model={d.biomedicEquipment.modell}
                                serie={d.biomedicEquipment.serie}
                                plate={d.licensePlate}
                                moduleWork={d.moduleWork.id}
                                state={d.state}
                                id={d.id}
                                location={d.location ? d.location.name : ""}
                                onChange={() => setEquipToUpload({
                                    "image": d.biomedicEquipment.image,
                                    "name": d.name,
                                    "brand": d.biomedicEquipment.brand,
                                    "model": d.biomedicEquipment.modell,
                                    "serie": d.biomedicEquipment.serie,
                                    "plate": d.licensePlate,
                                    "location": d.biomedicEquipment.location,
                                    "id": d.id
                                })}
                                setVisibleModal={setVisibleModalUploPDF}
                            />
                        ))}
                    </div>
                    {!isFiltered ?
                        <div className='showingText'>
                            Mostrando {((currentPage) * pageSize) + 1} - {Math.min((currentPage + 1) * pageSize, quantityEquipsWithCaseReports)} de {quantityEquipsWithCaseReports} elementos
                        </div>
                        :
                        <div className='showingText'>
                            Mostrando {((currentPageFiltered) * pageSize) + 1} - {Math.min((currentPageFiltered + 1) * pageSize, quantityEquipsWithCaseReports)} de {quantityEquipsWithCaseReports} elementos
                        </div>
                    }
                    <div className='divButtonsPages'>
                        <div className='sidesButtons' onClick={() => onSides(-1)}>Anterior</div>
                        {!isFiltered ?
                            <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                                {generateNButtons(totalPages, setCurrentPage, currentPage, totalPages)}
                            </Radio.Group>
                            :
                            <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                                {generateNButtons(totalPages, setCurrentPageFiltered, currentPageFiltered, totalPages)}
                            </Radio.Group>
                        }
                        <div className='sidesButtons' onClick={() => onSides(1)}>Siguiente</div>
                    </div>
                </>
            }
            <ModalSearch currentPage={currentPage}></ModalSearch>
            <ModalSearchEquipment visibleModal={visibleModal1} setVisibleModal={setVisibleModal1} />
            <ModalUploPDF></ModalUploPDF>
        </div>
    )
}