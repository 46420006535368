import { useHistory, useLocation } from 'react-router-dom';
import { PdfCaseReport } from "../../../components/PdfCaseReport/PdfCaseReport";
import { Page, Document, pdf, PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PdfCaseReportSystem } from '../../../components/PdfCaseReportSystem/PdfCaseReportSystem';
import { PdfCaseReportPowerPlant } from '../../../components/PdfCaseReportPowerPlant/PdfCaseReportPowerPlant';
import { PdfAirConditionatersAndRefri } from '../../../components/PdfAirConditionatersAndRefri/PdfAirConditionatersAndRefri';

export const MasivePDF = () => {
    const location = useLocation()
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const { moduleWork } = useSelector(state => state.Area)


    //get the data from the location
    const { data } = location.state

    const CombinedDocuments = () => (
        <>
            {data && data.map((element, index) => {
                switch (moduleWork[0].moduleWork.id) {
                    case 1:
                        return <PdfCaseReport key={index} fullDataCaseReport={element} enterprise={enterpriseImage} />
                    case 2:
                        if (element.equipment.computersEquipment) {
                            return <PdfCaseReportSystem key={index} fullDataCaseReport={element} enterprise={enterpriseImage} />
                        }
                        if (element.equipment.networkingAndCommunicationsEquipment) {
                            return <PdfCaseReportSystem key={index} fullDataCaseReport={element} enterprise={enterpriseImage} />
                        }
                        if (element.equipment.peripheralsAndAccessoriesEquipment) {
                            return <PdfCaseReportSystem key={index} fullDataCaseReport={element} enterprise={enterpriseImage} />
                        }
                        break;
                    case 3:
                        console.log(element)
                        if (element.equipment.name=="Planta eléctrica") {
                            return <PdfCaseReportPowerPlant key={index} fullDataCaseReport={element} enterprise={enterpriseImage} />
                        }
                        if (element.equipment.name=="Aire acondicionado") {
                            return <PdfAirConditionatersAndRefri key={index} fullDataCaseReport={element} enterprise={enterpriseImage} />
                        }
                        break;
                }
            })}
        </>
    );

    if (!data || data.length === 0) {
        return <div>No hay reportes para la fecha y dependencia seleccionadas</div>;
    }

    return (
        <PDFViewer style={{ width: '80vw', height: '80vw' }}>
            <Document>
                <CombinedDocuments />
            </Document>
        </PDFViewer>
    )
}