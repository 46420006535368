import { Button, DatePicker, Modal, Select } from "antd"
import { EquipmentActions } from "../../services/Equipment/EquipmentSlice"
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { Input } from "../Input/Input"
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useUniqueSortedAreas } from "../../hooks/useUniqueSortedAreas";

export const ModalSearchEquipment = ({ visibleModal, setVisibleModal, setFormData }) => {
    const [nameEquip, setNameEquip] = useState(false)
    const [state, setState] = useState(false)
    const [location, setLocation] = useState(false)

    const [year, setYear] = useState(false)
    const [areaSelected, setAreaSelected] = useState(false)
    const [dependencySelected, setDependencySelected] = useState(undefined)
    const [licensePlateToSearch, setLicensePlateToSearch] = useState(false)
    const [modellToSearch, setModellToSearch] = useState(false)
    const [serieToSearch, setSerieToSearch] = useState(false)
    const { moduleWork } = useSelector(state => state.Area)
    const { dependencys } = useSelector(state => state.Login)

/*     const { caseReportsSearched, loadingPDF, loadingCaseReport, loadingFullDataCaseReport, CaseReportCreatedId } = useSelector(state => state.CaseReport)
 */    const { loadingEquipment, locations, states, equipmentsSearched } = useSelector(state => state.Equipment)
    const areas = useUniqueSortedAreas();
    const { Option } = Select
    const dispatch = useDispatch()

    useEffect(() => {
        equipmentsSearched && setVisibleModal(false)
    }, [equipmentsSearched, setVisibleModal])

    return (
        <Modal width={'44vw'} className='ModalSearchEquipment' visible={visibleModal} footer={null} onCancel={() => setVisibleModal(false)}>
            <p className="titleModal">Busqueda</p>
            <Input setData={setNameEquip} label={"Nombre del equipo"} width={"35vw"}></Input>
            <div className="selectsDependency">
                <p className="label" style={{ marginTop: '-1vw', marginBottom: '0vw' }}>Area</p>
                <Select
                    className="select"
                    placeholder='Area'
                    onChange={e => {
                        setAreaSelected(e)
                    }}
                >
                    {dependencys && areas.map(d => (
                        <Option key={d.id} value={d.id} >{d.name}</Option>
                    ))}
                </Select>
                <p className="label" style={{ marginTop: '1vw', marginBottom: '0vw' }}>Dependencia</p>
                {areaSelected &&

                    <Select
                        className="select"
                        placeholder='Dependencia'
                        onChange={e => setDependencySelected(e)}
                    >
                        {areaSelected && dependencys.filter(item => item.area.id === areaSelected).map(d => (
                            <Option key={d.id} value={d.id}>{d.name}</Option>
                        ))}
                    </Select>}
            </div>

            <Input setData={setLicensePlateToSearch} label={"Placa del equipo"} width={"35vw"}></Input>
            <Input setData={setModellToSearch} label={"Modelo"} width={"35vw"}></Input>
            <Input setData={setSerieToSearch} label={"Serie"} width={"35vw"}></Input>

            {/* <div>
                <p className="label">Ubicación</p>
                <Select
                    className="select"
                    showSearch
                    placeholder="Ubicación"
                    optionFilterProp="children"
                    onChange={setLocation}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                >
                    {locations && locations.map(d => (
                        <Option key={d} value={d}>{d}</Option>
                    ))}
                </Select>
            </div> */}
            <div className="divButton">
                {!loadingEquipment ?
                    <Button className="button" onClick={() => {
                        dispatch(EquipmentActions.search({
                            "dependency": dependencySelected,
                            "area": areaSelected,
                            "nameEquip": nameEquip,
                            "year": year,
                            "location": location,
                            "licensePlate": licensePlateToSearch,
                            "modell": modellToSearch,
                            "serie": serieToSearch,
                            "take": 20,
                            "page": 0,
                            "moduleWork": moduleWork[0].moduleWork.id,
                        }))
                        setFormData && setFormData({
                            "dependency": dependencySelected,
                            "area": areaSelected,
                            "nameEquip": nameEquip,
                            "year": year,
                            "location": location,
                            "licensePlate": licensePlateToSearch,
                            "moduleWork": moduleWork[0].moduleWork.id,
                        })
                    }}><SearchOutlined className="plus" /> Buscar</Button>
                    :
                    <Button className="button" ><LoadingOutlined className="plus" /> Cargando</Button>
                }
            </div>
        </Modal >
    )
}