import { DatePicker, Select, TimePicker, ConfigProvider, message } from "antd"
import moment from 'moment';
import esES from 'antd/es/locale/es_ES';
import 'moment/locale/es';
import 'antd/dist/antd.css';
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { AreaActions } from "../../services/Area/AreaSlice"
import { Input } from "../Input/Input"
import { EquipmentActions } from "../../services/Equipment/EquipmentSlice";
import { TimelineActions } from "../../services/Timeline/TimelineSlice";
import { EnterpriseActions } from "../../services/Enterprise/EnterpriseSlice";
import { useUniqueSortedAreas } from "../../hooks/useUniqueSortedAreas";

moment.locale('es');

export const FormTraining = () => {
    const [headquarter, setHeadquarter] = useState(undefined)
    const [search, setSearch] = useState("")
    const [visibleOptions, setVisibleOptions] = useState(-1)
    const [equipSelected, setEquipSelected] = useState(false)
    const { dependency, dependencySelected, moduleWork } = useSelector(state => state.Area)
    const { equipsForTimeline, loadingEquipsForTimeline } = useSelector(state => state.Equipment)
    const { equipsToTrain } = useSelector(state => state.Timeline)
    const { headquarters } = useSelector(state => state.Enterprise)
    const { enterprise, idUser, dependencys } = useSelector(state => state.Login)
    const [date, setDate] = useState(false)
    const [areaSelected, setAreaSelected] = useState(undefined)
    const [hours, setHours] = useState(0)
    const [activities, setActivities] = useState("")
    const [file, setFile] = useState(false)

    const { Option } = Select
    const dispatch = useDispatch()

    const areas = useUniqueSortedAreas();

    const { RangePicker } = DatePicker;

    useEffect(() => {
        enterprise && dispatch(EnterpriseActions.getHeadquarters(enterprise.id))
    }, [enterprise])

    /* const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result.split(',')[1];
                setPdf(base64);
            };
            reader.readAsDataURL(file);
        } else {
            alert('Por favor seleccione un archivo PDF válido');
        }
    }; */

    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }

    const handleFileChange = ({ target }) => {
        if (target.files.length < 1 || !target.validity.valid) {
            return
        }
        const file = target.files[0];
        const fileSizeInBytes = file.size;
        const fileSizeInKilobytes = fileSizeInBytes / 1024; // convertir a Kilobytes

        if (fileSizeInKilobytes > 341) { // cambiar el 341 al tamaño máximo que desees permitir
            message.error("El archivo es demasiado grande (341KB max). Intente comprimir el documento");
        } else {
            fileToBase64(file, (err, result) => {
                if (err) {
                    message.error("Error al cargar el archivo")
                } else if (result) {
                    setFile(result)
                    message.success("Archivo cargado")
                }
            })
        }
    }

    return (
        <div className="FormTraining">
            <div className='titleDiv'><p className='inventoryText'>Programar Capacitacion</p></div>
            <div className="row">
                <Select
                    className='select'
                    placeholder={"Sede"}
                    value={headquarter}
                    onChange={event => setHeadquarter(event)}
                    color='primary'
                >
                    <Option value={"null"}>
                        Sede principal
                    </Option>
                    {headquarters && headquarters.map(d => (
                        <Option key={d.id} value={d.id}>{d.name}</Option>
                    ))}
                </Select>
                <Select
                    className='select'
                    placeholder={"Area"}
                    value={areaSelected}
                    onChange={e => {
                        setAreaSelected(e)
                        dispatch(AreaActions.setDependencySelected(undefined))
                    }}
                    color='primary'
                >
                    {dependencys && areas.map(d => (
                        <Option key={d.id} value={d.id} >{d.name}</Option>
                    ))}
                </Select>
                <Select
                    className='select'
                    placeholder={"Dependencia"}
                    value={dependencySelected}
                    onChange={e => dispatch(AreaActions.setDependencySelected(e))}
                    color='primary'
                >
                    {areaSelected && dependencys.filter(item => item.area.id === areaSelected).map(d => (
                        <Option key={d.id} value={d.id}>{d.name}</Option>
                    ))}
                </Select>
                <div className="divDate">
                    <p className="label">* Fecha</p>
                    <ConfigProvider locale={esES}>
                        <DatePicker onChange={setDate} />
                    </ConfigProvider>
                </div>

            </div>
            <Input va={hours} setData={setHours} label={"Horas"} mt={"1vw"} />
            <Input va={activities} setData={setActivities} label={"Actividades"} />
            <input
                type="file"
                onChange={handleFileChange}
                placeholder="Seleccione pdf"
                className="search"
                style={{ width: '30vw' }}
            />
            <button
                className="search"
                style={{ width: '23vw' }}
                onClick={() => {
                    if (dependencySelected && date && file && hours && activities) {
                        dispatch(TimelineActions.createTraining([{
                            "dependency": dependencySelected,
                            "date": date,
                            "pdf": file,
                            "hours": parseFloat(hours),
                            "activities": activities,
                            "dbState": true,
                            "headquarter": headquarter === "null" ? null : headquarter,
                            "userCreator": idUser,
                        }]))

                    } else {
                        message.warning("Por favor llene todos los campos")
                    }
                }}
            >
                Guardar
            </button>
            {/* {dependencySelected && date &&
                <div style={{ marginTop: '1vw', display: "flex" }}>
                    <Input va={search} setData={setSearch} placeholder={"Buscar nombre"} />
                    {search != "" &&
                        <button
                            className="search"
                            onClick={() => dispatch(EquipmentActions.getEquipsForTimeline({ "dependency": dependencySelected, "string": search }))}
                        >
                            {loadingEquipsForTimeline ? <LoadingOutlined /> : "Buscar"}
                        </button>
                    }
                    {equipsForTimeline &&
                        <div
                            className="customeSelect"
                            onClick={() => setVisibleOptions(prev => prev * -1)}
                        >
                            Equipos
                            <DownOutlined className="icon"
                            />
                        </div>
                    }
                    {visibleOptions !== -1 &&
                        <div className="optionsCustomeSelect">
                            {equipsForTimeline.map(d => (
                                <div style={{ cursor: "pointer", display: "flex" }} className="equipInSelect" key={d.id} onClick={() => {
                                    setVisibleOptions(-1)
                                    setEquipSelected({
                                        "id": d.id,
                                        "name": d.name,
                                        "brand": d.biomedicEquipment.brand,
                                        "modell": d.biomedicEquipment.modell,
                                        "serie": d.biomedicEquipment.serie,
                                        "licensePlate": d.licensePlate,
                                        "state": d.state
                                    })
                                }}>
                                    <div className="dataDiv">
                                        <div><p className='pFontData name'>{d.name}</p></div>
                                        <div><p className='pFontData'>{d.biomedicEquipment.brand}</p></div>
                                        <div><p className='pFontData'>{d.biomedicEquipment.modell}</p></div>
                                        <div><p className='pFontData'>{d.biomedicEquipment.serie}</p></div>
                                        <div><p className='pFontData'>{d.licensePlate}</p></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                    {equipSelected &&
                        <div className="equipInSelect" style={{ display: visibleOptions === -1 ? "flex" : "none" }}>
                            <div className="dataDiv">
                                <div><p className='pFontData name'>{equipSelected.name}</p></div>
                                <div><p className='pFontData'>{equipSelected.brand}</p></div>
                                <div><p className='pFontData'>{equipSelected.modell}</p></div>
                                <div><p className='pFontData'>{equipSelected.serie}</p></div>
                                <div><p className='pFontData'>{equipSelected.licensePlate}</p></div>
                            </div>
                        </div>
                    }
                </div>}
            {dependencySelected && headquarter !== false && date &&
                <div style={{ marginTop: '0.5vw' }}>
                    {equipSelected &&
                        <input
                            type="file"
                            onChange={handleFileChange}
                            placeholder="Seleccione pdf"
                            className="search"
                            style={{ width: '30vw' }}
                        />
                    }
                    {pdf && <p>Archivo cargado: {pdf.name}</p>}
                    {equipSelected && pdf &&
                        <button
                            className="search"
                            style={{ width: '23vw' }}
                            onClick={() => {
                                const data = {
                                    "id": equipSelected.id,
                                    "name": equipSelected.name,
                                    "brand": equipSelected.brand,
                                    "modell": equipSelected.modell,
                                    "serie": equipSelected.serie,
                                    "licensePlate": equipSelected.licensePlate,
                                    "state": equipSelected.state,
                                    "date": date,
                                    "headquarter": headquarter,
                                    "pdf": pdf
                                }
                                dispatch(TimelineActions.setManyEquipsToTrain([data]))
                            }}
                        >
                            Cargar equipo
                        </button>
                    }
                </div>
            } */}
            {/*  <hr style={{ marginTop: '2.4vw', backgroundColor: '#0000004D', width: '80vw' }}>
            </hr>
            <div className="columnsNameFormTimeline">
               <p style={{ marginLeft: "1vw" }}>Nombre</p>
                <p>Marca</p>
                <p>Modelo</p>
                <p>Serie</p>
                <p>Placa</p>
                <p style={{ marginRight: "1vw" }}>Estado</p>
                <p style={{ marginRight: "2vw" }}>Fecha</p>
            </div>

            {equipsToTrain && equipsToTrain.length > 0 && equipsToTrain.map((d, index) => (
                <div className='equips' key={index}>
                    <div className='dataDiv' >
                        <div><p className='pFontData name'>{d.name}</p></div>
                        <div><p className='pFontData'>{d.brand}</p></div>
                        <div><p className='pFontData'>{d.modell}</p></div>
                        <div><p className='pFontData'>{d.serie}</p></div>
                        <div><p className='pFontData'>{d.licensePlate}</p></div>
                        {d.state && (d.state === "Funcional" ? <div className='status'><p className='pFontStatus'>Funcional</p></div>
                            : d.state === "No funcional" ? <div className='status1'><p className='pFontStatus'>{d.state}</p></div>
                                : d.state === "Dado de baja" ? <div className='status2'><p className='pFontStatus'>Baja</p></div>
                                    : <div className='status3'><p className='pFontStatus'>Antiguo</p></div>)}
                        <div><p className='pFontData'>{d.date.format('YYYY-MM-DD')}</p></div>
                    </div>
                </div>))} */}
        </div >
    )
}