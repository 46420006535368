import { useEffect, useRef, useState } from "react"
import React from "react";
/* import { Input } from '../../../components/Input/Input'
 */import { FormEquipmentResume } from "../../../components/FormEquipmentResume/FormEquipmentResume";
import { useDispatch, useSelector } from "react-redux";
import { Button, DatePicker, message, Modal, Radio, Select } from 'antd'
/* import { MenuItem, Select } from "@material-ui/core"
 */import Icon, { PlusOutlined, SearchOutlined, LoadingOutlined, CloseCircleFilled } from "@ant-design/icons";
/* import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl'; */
import { DepMunActions } from "../../../services/DepMun/DepMunSlice";
import { EquipmentActions } from "../../../services/Equipment/EquipmentSlice";
import { Input } from "../../../components/Input/Input";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import { Document, Page, PDFDownloadLink, Text, View } from "@react-pdf/renderer";
import { Equipment } from "../../../components/Equipment/Equipment";
import { FormEquipmentSystem } from "../../../components/FormEquipmentSystem/FormEquipmentSystem";
import { EquipmentSystem } from "../../../components/EquipmentSystem/EquipmentSystem";
import { FormEquipmentIndustrial } from "../../../components/FormEquipmentIndustrial/FormEquipmentIndustrial";
import { EquipmentIndustrial } from "../../../components/EquipmentIndustrial/EquipmentIndustrial";
import { FormEquipmentVehicle } from "../../../components/FormEquipmentVehicle/FormEquipmentVehicle";
import ArimoBold from '../../../assets/fonts/Arimo-Bold.ttf';
import { EquipmentVehicle } from "../../../components/EquipmentVehicle/EquipmentVehicle";
import { useUniqueSortedAreas } from "../../../hooks/useUniqueSortedAreas";
import { EnterpriseActions } from "../../../services/Enterprise/EnterpriseSlice";

export const Inventory = () => {
    const [option, setOption] = useState('Search')
    const [edit, setEdit] = useState(false)
    const [equipData, setEquipData] = useState()
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleExitSearch, setVisibleExitSearch] = useState(false)
    const [textPDF, setTextPDF] = useState("")
    const [nameEquipToFilter, setNameEquipToFilter] = useState(false)
    const [stateToFilter, setStateToFilter] = useState(false)
    const [locationToFilter, setLocationToFilter] = useState(false)
    const [yearToFilter, setYearToFilter] = useState(false)
    const [areaSelectedToFilter, setAreaSelectedToFilter] = useState(false)
    const [dependencySelectedToFilter, setDependencySelectedToFilter] = useState(false)
    const [licensePlateToSearchToFilter, setLicensePlateToSearchToFilter] = useState(false)
    const [headquarterToFilter, setHeadquarterToFilter] = useState(false)

    const { Option } = Select;
    const dispatch = useDispatch()

    const { equipmentsSearched, equipmentsFiltered, loadingEquipment, quantity, fullDataEquipment, isFiltered, textXLSX, equipmentsToXLSX } = useSelector(state => state.Equipment)
    const { dependency, moduleWork } = useSelector(state => state.Area)
    const { dependencys, fullUser } = useSelector(state => state.Login)
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageFiltered, setCurrentPageFiltered] = useState(0);

    useEffect(() => {
        !isFiltered && moduleWork && dispatch(EquipmentActions.getEquipmentsByModuleWork({ "moduleWork": moduleWork[0].moduleWork.id, "take": 8, "page": currentPage }))
    }, [currentPage, dispatch, moduleWork])

    useEffect(() => {
        isFiltered && dispatch(EquipmentActions.search(
            {
                "dependency": dependencySelectedToFilter,
                "nameEquip": nameEquipToFilter,
                "location": locationToFilter,
                "licensePlate": licensePlateToSearchToFilter,
                "take": 8,
                "page": currentPageFiltered,
                "moduleWork": moduleWork[0].moduleWork.id,
                "headquarter": headquarterToFilter
            }))
    }, [currentPageFiltered, dispatch])

    useEffect(() => {
        dispatch(EquipmentActions.getLocations())
        dispatch(EquipmentActions.getStates())
    }, [dispatch])

    const onExitSearch = () => {
        setVisibleExitSearch(false)
        dispatch(EquipmentActions.setEquipmentsFiltered(false))
    }

    useEffect(() => {
        dispatch(DepMunActions.getDepartments())
    }, [dispatch])


    const buttons = (t) => {
        if (moduleWork !== false) {
            if (t === "SearchModal") {
                setVisibleModal(true);
                dispatch(EquipmentActions.setTextXLSX(""))
                setTextPDF("");
                setCurrentPage(0)
                setCurrentPageFiltered(0)
            } else {
                setOption(t);
                setEdit(false);
            }
        } else {
            message.warn("Debe elegir un módulo de trabajo")
        }
        dispatch(EquipmentActions.setIsFiltered(false))
    }

    const onSearch = (nameEquip, location, dependencySelected, licensePlate, headquarter) => {
        setVisibleModal(false)
        dispatch(EquipmentActions.search(
            {
                "dependency": dependencySelected,
                "nameEquip": nameEquip,
                "location": location,
                "licensePlate": licensePlate,
                "take": 8,
                "page": currentPageFiltered,
                "moduleWork": moduleWork[0].moduleWork.id,
                "headquarter": headquarter
            }))
        setDependencySelectedToFilter(dependencySelected)
        setNameEquipToFilter(nameEquip)
        setLocationToFilter(location)
        setLicensePlateToSearchToFilter(licensePlate)
        setHeadquarterToFilter(headquarter)

    }

    const createXLSX = (nameEquip, state, location, dependency) => {
        dispatch(EquipmentActions.getEquipmentsToXLSX({ "moduleWork": moduleWork[0].moduleWork.id, "take": 0, "page": 0, "dependency": dependency }))
    }

    const createPDF = (nameEquip, state, location) => {
        dispatch(EquipmentActions.searchEquipment(
            {
                "area": dependency,
                "nameEquip": nameEquip,
                "state": state,
                "location": location
            }))
        setTextPDF("Descargar")
    }

    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        sheetData.unshift(header);
        return sheetData;
    }

    async function downloadXLSX() {
        if (textXLSX === "Descargar") {
            var data = [];
            console.log(equipmentsToXLSX)
            equipmentsToXLSX.map(d => {
                if (d.biomedicEquipment) {
                    data.push({
                        nombre: d.name,
                        marca: d.biomedicEquipment.brand,
                        modelo: d.biomedicEquipment.modell,
                        serie: d.biomedicEquipment.serie,
                        placa: d.licensePlate,
                        registroSanitario: d.biomedicEquipment.healthRegister,
                        clasificacionRiesgo: d.biomedicEquipment.riskClasification,
                        ubicacion: d.biomedicEquipment.location,
                        estado: d.state,
                        agente: d.userCreator.person.name + " " + d.userCreator.person.lastName
                    })
                }
                return null
            })
            let header = ["Nombre", "Marca", "Modelo", "Serie", "Placa", "Registro Sanitario", "Clasificación del riesgo", "Ubicación", "Estado", "Agente"];

            const templatePath = "/assets/PlantillaDescargarInventario.xlsx";

            fetch(templatePath)
                .then(response => response.arrayBuffer())
                .then(arrayBuffer => {
                    // Cargar la plantilla desde el array buffer
                    return XlsxPopulate.fromDataAsync(arrayBuffer);
                })
                .then(workbook => {
                    message.loading("Descargando Excel")
                    const sheet = workbook.sheet(0);

                    // Modificar datos en la hoja, comenzando desde la fila 4
                    const sheetData = getSheetData(data, header);
                    sheet.cell("A3").value(sheetData);

                    // Descargar el archivo modificado
                    return workbook.outputAsync().then((res) => {
                        saveAs(res, "BusquedaInventario.xlsx");
                    });
                })
                .catch((error) => {
                    message.error("Error al intentar descargar Excel")
                    console.error("Error al cargar la plantilla:", error);
                });
        }
    }

    const MyDocument = () => (
        <Document>
            <Page size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }}>
                <View style={{ border: '3px solid #000', marginTop: '1%', width: '93%', height: '2%', flexDirection: 'row' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>NOMBRE</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>MARCA</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>MODELO</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>SERIE</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>PLACA</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>UBICACIÓN</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>ESTADO</Text>
                    </View>
                    <View style={{ height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>AGENTE</Text>
                    </View>
                </View>
                {equipmentsSearched.length > 0 && equipmentsSearched.map(d => (
                    <View style={{ border: '3px solid #000', width: '93%', height: '2%', flexDirection: 'row' }}>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.name}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.biomedicEquipment.brand}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.biomedicEquipment.modell}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.biomedicEquipment.serie}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.licensePlate}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.biomedicEquipment.location}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.state === "Fuera de servicio" ? "Fuera de servicio" : "Funcional"}</Text>
                        </View>
                        {/* <View style={{ height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.dependency.dependencyUser[0].userAssigned.person.name + " " + d.case.userAssigned.person.lastName}</Text>
                        </View> */}
                    </View>
                ))}
            </Page>
        </Document>
    );

    const DownloadSvg = () => (
        <svg width="2vw" height="2vw" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.38247 4.4625V0H1.16852C0.755297 0 0.422852 0.351094 0.422852 0.7875V16.0125C0.422852 16.4489 0.755297 16.8 1.16852 16.8H11.6079C12.0212 16.8 12.3536 16.4489 12.3536 16.0125V5.25H8.12814C7.71802 5.25 7.38247 4.89562 7.38247 4.4625ZM9.75775 11.3977L6.762 14.5379C6.55539 14.7548 6.2217 14.7548 6.01509 14.5379L3.01935 11.3977C2.70399 11.0673 2.92521 10.5 3.36888 10.5H5.39401V7.875C5.39401 7.58494 5.61646 7.35 5.89112 7.35H6.88535C7.16001 7.35 7.38247 7.58494 7.38247 7.875V10.5H9.40759C9.85127 10.5 10.0725 11.0673 9.75775 11.3977ZM12.1361 3.44531L9.09441 0.229687C8.95459 0.0820312 8.76507 0 8.56622 0H8.3767V4.2H12.3536V3.99984C12.3536 3.79312 12.2759 3.59297 12.1361 3.44531Z" fill="white" />
        </svg>
    );

    const ModalSearch = () => {
        const [nameEquip, setNameEquip] = useState(false)
        const [state, setState] = useState(false)
        const [location, setLocation] = useState(false)
        const [year, setYear] = useState(false)
        const [areaSelected, setAreaSelected] = useState(false)
        const [dependencySelected, setDependencySelected] = useState(false)
        const [licensePlateToSearch, setLicensePlateToSearch] = useState(false)
        const [headquarter, setHeadquarter] = useState(undefined)

        const { headquarters } = useSelector(state => state.Enterprise)
        const { enterprise, idUser, boss, dependencys, userCredentialsFirebase } = useSelector(state => state.Login)


        const areas = useUniqueSortedAreas();

        useEffect(() => {
            enterprise && dispatch(EnterpriseActions.getHeadquarters(enterprise.id))
        }, [enterprise])

        return (
            <Modal width={'44vw'} className='ModalSearchEquipment' visible={visibleModal} footer={null} onCancel={() => setVisibleModal(false)}>
                <p className="titleModal">Busqueda</p>
                <Input setData={setNameEquip} label={"Nombre del equipo"} width={"35vw"}></Input>
                <div className="selectsDependency">
                    <p className="label" style={{ marginTop: '-1vw', marginBottom: '0vw' }}>Area</p>
                    <Select
                        className="select"
                        placeholder='Area'
                        onChange={e => {
                            setAreaSelected(e)
                        }}
                    >
                        {dependencys && areas.map(d => (
                            <Option key={d.id} value={d.id} >{d.name}</Option>
                        ))}
                    </Select>
                    <p className="label" style={{ marginTop: '1vw', marginBottom: '0vw' }}>Dependencia</p>
                    {areaSelected &&
                        <Select
                            className="select"
                            placeholder='Dependencia'
                            onChange={e => setDependencySelected(e)}
                        >
                            {areaSelected && dependencys.filter(item => item.area.id === areaSelected).map(d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))}
                        </Select>}
                    <p className="label" style={{ marginTop: '1vw', marginBottom: '0vw' }}>Sede</p>
                    <Select
                        className='select'
                        placeholder="Sede"
                        value={headquarter}
                        onChange={event => setHeadquarter(event)}
                        color='primary'
                    >
                        <Option value={"Sede principal"}>Sede principal</Option>
                        {headquarters && headquarters.map(d => (
                            <Option key={d.id} value={d.id}>{d.name}</Option>
                        ))}
                    </Select>
                </div>
                {/*                 <DatePicker onChange={e => setYear(e)} picker='year' style={{width:'35vw', height:'3vw'}}/>
 */}                <Input setData={setLicensePlateToSearch} label={"Placa del equipo"} width={"35vw"}></Input>
                {/* <div>
                    <p className="label">Ubicación</p>
                    <Select
                        className="select"
                        showSearch
                        placeholder="Ubicación"
                        optionFilterProp="children"
                        onChange={setLocation}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {locations && locations.map((d, i) => (
                            <Option key={i} value={d.location}>{d.location}</Option>
                        ))}
                    </Select>
                </div> */}
                <div className="divExports" >
                    {textXLSX === "" ?
                        <div className="divXLSX" onClick={() => createXLSX(nameEquip, state, location, dependencySelected)}>
                            <Icon component={DownloadSvg} />
                            Reporte Inventario Excel
                        </div>
                        :
                        <div className="divXLSX" onClick={() => downloadXLSX()}>
                            <Icon component={DownloadSvg} />
                            {textXLSX}
                        </div>}
                    {/* {textPDF === "" ? <div className="divPDF" onClick={() => createPDF(nameEquip, state, location)}>
                        <Icon component={DownloadSvg} />
                        Reporte Inventario PDF
                    </div>
                        :
                        <PDFDownloadLink document={<MyDocument />} fileName={"BusquedaInventario"}>
                            <div className="divPDF">
                                <Icon component={DownloadSvg} />
                                {textPDF}
                            </div>
                        </PDFDownloadLink>} */}

                </div>
                <div className="divButton">
                    <Button className="button" onClick={() => onSearch(nameEquip, location, dependencySelected, licensePlateToSearch, headquarter)}><SearchOutlined className="plus" /> Buscar</Button>
                </div>
            </Modal >
        )
    }
    const pageSize = 8
    const totalPages = Math.ceil(quantity / pageSize);


    const onSides = (n) => {
        if (isFiltered) {
            if (currentPageFiltered < totalPages - 1 & n === 1) {
                setCurrentPageFiltered(prev => prev + n)
            }
            if (currentPageFiltered > 0 & n === -1) {
                setCurrentPageFiltered(prev => prev + n)
            }
        } else {
            if (currentPage < totalPages - 1 & n === 1) {
                setCurrentPage(prev => prev + n)
            }
            if (currentPage > 0 & n === -1) {
                setCurrentPage(prev => prev + n)
            }
        }
    }

    const generateNButtons = (N, onClick, currentPage, totalPages) =>
        [...Array(N).keys()].map(i => (
            currentPage === i ? <div className='radiobuttonSelected'
                key={`Button_${i}`}
                onClick={() => onClick(i)}
            >
                {i + 1}
            </div>
                :
                Math.abs(i - currentPage) <= 2 ?
                    <div className='radiobutton'
                        key={`Button_${i}`}
                        onClick={() => onClick(i)}
                    >
                        {i + 1}
                    </div>
                    :
                    i === totalPages - 1 ?
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            {i + 1}
                        </div>
                        :
                        Math.abs(i - currentPage) === 3 &&
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            ...
                        </div>
        ))

    return (
        <div className='inventoryDiv'>
            <div className='titleDiv' ><p className='inventoryText'>Inventarios</p></div>
            <div className='buttonsSection'>
                {option === 'Search' ? <div className="buttons">
                    {fullUser.canCreate && <Button className="Unselected add" onClick={() => buttons('Add')}><PlusOutlined className="plus" /> Añadir </Button>}
                    <Button className="Selected" onClick={() => buttons('SearchModal')}>{!loadingEquipment ? <SearchOutlined className="plus" /> : <LoadingOutlined className='plus' />} Buscar</Button>
                    {visibleExitSearch && <CloseCircleFilled className="closeIcon" onClick={() => onExitSearch()} />}
                </div>
                    :
                    option === 'Add' ? <div className="buttons">
                        {fullUser.canCreate && <Button className="Selected add" onClick={() => buttons('Add')}><PlusOutlined className="plus" /> Añadir</Button>}
                        <Button className="Unselected" onClick={() => buttons('Search')}>{!loadingEquipment ? <SearchOutlined className="plus" /> : <LoadingOutlined className='plus' />} Buscar</Button>
                    </div> : true}
                {/* <div className="filters">
                    {option === 'Search' && <div>
                        <Input labelstyle={'input'} placeholder='Buscar'></Input>
                    </div>}
                </div> */}
            </div>
            {
                option === 'Add' &&
                <div className='body'>
                    <div className='form'>
                        {
                            (() => {
                                switch (moduleWork[0].moduleWork.id) {
                                    case 1:
                                        return <FormEquipmentResume setOption={setOption} />;
                                    case 2:
                                        return <FormEquipmentSystem setOption={setOption} />;
                                    case 3:
                                        return <FormEquipmentIndustrial setOption={setOption} />;
                                    case 4:
                                        return <FormEquipmentVehicle setOption={setOption} />;
                                }
                            })()
                        }
                    </div>
                </div>

            }

            {
                edit && fullDataEquipment &&
                <div className='body'>
                    <div className='form'>
                        {
                            (() => {
                                switch (moduleWork[0].moduleWork.id) {
                                    case 1:
                                        return <FormEquipmentResume edit={edit} data={equipData} />;
                                    case 2:
                                        return <FormEquipmentSystem setOption={setOption} edit={edit} data={equipData} />;
                                    case 3:
                                        return <FormEquipmentIndustrial edit={edit} data={equipData} />;
                                }
                            })()
                        }
                    </div>
                </div>}
            {/* {(option === 'Search' && !edit) &&
                <div className="selectsDependency">
                    <Select
                        className="select"
                        placeholder='Area'
                        onChange={e => {
                            setAreaSelected(e)
                            dispatch(AreaActions.setDependencySelected(undefined))

                        }}
                    >
                        {moduleWork && moduleWork[0].moduleWork.area.map(d => (
                            <Option key={d.id} value={d.id} >{d.name}</Option>
                        ))}
                    </Select>
                    {areaSelected &&
                        <Select
                            className="select"
                            placeholder='Dependencia'
                            value={dependencySelected}
                            onChange={e => dispatch(AreaActions.setDependencySelected(e))}
                        >
                            {areaSelected && moduleWork[0].moduleWork.area.filter(f => f.id === areaSelected)[0].dependency.map(d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))}
                        </Select>}
                </div>
            } */}
            {option === 'Search' && equipmentsSearched && !edit && <div className="divEquipmentsSearched">
                <div className="titleItems">
                    <p>Imagen</p>
                    <p style={{ width: '11%' }}>Nombre:</p>
                    <p style={{ width: '7%' }}>Marca:</p>
                    <p>Modelo:</p>
                    <p style={{ width: '12%' }}>Serie:</p>
                    <p>Placa:</p>
                    {moduleWork[0].moduleWork.id === 1 &&
                        <p style={{ width: '10%' }}>Registro Sanitario:</p>
                    }
                    {moduleWork[0].moduleWork.id === 1 &&
                        <p style={{ width: '10%' }}>Clasificación del Riesgo:</p>
                    }
                    <p style={{ width: '10%' }}>Ubicación:</p>
                    <p>Estado:</p>
                </div>

                {equipmentsSearched && equipmentsSearched.map(d => {
                    const componentMap = {
                        1: Equipment,
                        2: EquipmentSystem,
                        3: EquipmentIndustrial,
                        4: EquipmentVehicle,
                    };
                    const Component = componentMap[moduleWork[0].moduleWork.id];

                    return (
                        <Component
                            key={d.id}
                            data={d}
                            setEdit={setEdit}
                            setEquipData={setEquipData}
                            width={'80vw'}
                            height={'5vw'}
                        />
                    );
                })}
                <div className='showingText'>
                    Mostrando {((currentPage) * pageSize) + 1} - {Math.min((currentPage + 1) * pageSize, quantity)} de {quantity} elementos
                </div>
                <div className='divButtonsPages'>
                    <div className='sidesButtons' onClick={() => onSides(-1)}>Anterior</div>
                    {!isFiltered ?
                        <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                            {generateNButtons(totalPages, setCurrentPage, currentPage, totalPages)}
                        </Radio.Group>
                        :
                        <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                            {generateNButtons(totalPages, setCurrentPageFiltered, currentPageFiltered, totalPages)}
                        </Radio.Group>
                    }
                    <div className='sidesButtons' onClick={() => onSides(1)}>Siguiente</div>
                </div>
            </div>}
            <ModalSearch></ModalSearch>
        </div>
    )

}