import { Document, Image, Page, Font, Text, View } from "@react-pdf/renderer";
import blanco from '../../assets/images/blanco.png'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ArimoBold from '../../assets/fonts/Arimo-Bold.ttf';
import moment from "moment";

Font.register({
    family: 'Arimo-Bold',
    src: ArimoBold,
});

export const PdfAnualTimelines = ({ equipsWithTimelines, enterprise, year }) => {

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    const months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ];

    const periodOptions = [
        { value: 'mensual', label: 'Mensual', increment: 1 },
        { value: 'bimestral', label: 'Bimestral', increment: 2 },
        { value: 'trimestral', label: 'Trimestral', increment: 3 },
        { value: 'cuatrimestral', label: 'Cuatrimestral', increment: 4 },
        { value: 'Quintimestral', label: 'Quintimestral', increment: 5 },
        { value: 'semestral', label: 'Semestral', increment: 6 }
    ];

    function chunkArray(array, chunkSize) {
        const arrayCopy = [...array]; // Create a copy of the array
        const results = [];
        while (arrayCopy.length) {
            results.push(arrayCopy.splice(0, chunkSize));
        }
        return results;
    }
    const first20Elements = equipsWithTimelines.slice(0, 20);
    const remainingElements = equipsWithTimelines.slice(20);
    const remainingElementsChunks = chunkArray(remainingElements, 22);

    return (
        <Document>
            <Page size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }}>
                <View style={{ border: '3px solid #000', marginTop: '5%', width: '95%', height: '8%', flexDirection: 'row' }}>
                    <View style={{ width: '20%', alignItems: 'center', justifyContent: 'center', borderRight: '3px solid #000' }}>
                        <Image style={{ width: '60%', height: '60%' }} src={enterprise[0].image}></Image>
                    </View>
                    <View style={{ width: '75%', borderRight: '3px solid #000' }}>
                        <View style={{ height: '50%', width: '100%', borderBottom: '3px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '32px', textAlign: 'center' }}>CRONOGRAMA DE MANTENIMIENTO PREVENTIVO DE EQUIPOS BIOMEDICOS AÑO {new Date(year).getFullYear()}</Text></View>
                        <View style={{ height: '50%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '30px', textAlign: 'center' }}>E.S.E HOSPITAL MALVINAS HECTOR OROZCO OROZCO</Text></View>
                    </View>
                    <View style={{ width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                        <View style={{ height: '50%', width: '100%', borderBottom: '3px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '32px' }}>{" "}</Text></View>
                        <View style={{ height: '50%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '32px' }}>{" "}</Text></View>
                    </View>
                </View>

                <View style={{ border: '3px solid #000', marginTop: '2%', width: '95%', minHeight: '40px', flexDirection: 'column' }}>
                    <View style={{ width: '100%', height: '40px', display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '16.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>EQUIPO</Text></View>
                        <View style={{ width: '14.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>MARCA, MODELO, SERIE</Text></View>
                        <View style={{ width: '13%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>UBICACIÓN</Text></View>
                        <View style={{ width: '10%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>FRECUENCIA DE MTO</Text></View>
                        <View style={{ width: '10%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>ESTADO</Text></View>
                        <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>ENE</Text></View>
                        <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>FEB</Text></View>
                        <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>MAR</Text></View>
                        <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>ABR</Text></View>
                        <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>MAY</Text></View>
                        <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>JUN</Text></View>
                        <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>JUL</Text></View>
                        <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>AGO</Text></View>
                        <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>SEP</Text></View>
                        <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>OCT</Text></View>
                        <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>NOV</Text></View>
                        <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>DIC</Text></View>
                    </View>

                    {first20Elements.map((d, i) => {
                        let selectedMonths = {};

                        d.timeline.forEach(t => {
                            const increment = periodOptions.find(option => option.value === t.periodicity)?.increment || false;

                            if (increment) {
                                let currentMonth = moment.utc(t.month).clone();

                                for (let j = currentMonth.month(); j < 12; j += increment) {
                                    if (currentMonth.isSameOrAfter(t.month)) {
                                        selectedMonths[months[currentMonth.month()]] = true;
                                    }
                                    currentMonth.add(increment, 'months');
                                }
                            }
                        });

                        return (
                            <View key={i} style={{ width: '100%', height: '100px', flexDirection: 'row', borderTop: '3px solid #000' }}>
                                <View style={{ width: '16.5%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}>
                                    <Text style={{ fontSize: 17 }}>{d.name}</Text>
                                </View>
                                <View style={{ width: '14.5%', height: '100%', borderRight: '3px solid #000' }}>
                                    <Text style={{ fontSize: 17 }}>MARCA: {d.biomedicEquipment.brand}</Text>
                                    <Text style={{ fontSize: 17 }}>MODELO: {d.biomedicEquipment.modell}</Text>
                                    <Text style={{ fontSize: 17 }}>SERIE: {d.biomedicEquipment.serie} PLACA: {d.licensePlate}</Text>
                                </View>
                                <View style={{ width: '13%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}>
                                    <Text style={{ fontSize: 17 }}>{d.biomedicEquipment.location}</Text>
                                </View>
                                <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}>
                                    <Text style={{ fontSize: 17 }}>{d.timeline[0].periodicity}</Text>
                                </View>
                                <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}>
                                    <Text style={{ fontSize: 17 }}>PROGRAMADO</Text>
                                </View>
                                {months.map((month) => (
                                    <View key={month} style={{ width: '3%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}>
                                        <Text style={{ fontSize: 17 }}>{selectedMonths[month] ? "X" : " "}</Text>
                                    </View>
                                ))}
                            </View>
                        );
                    })}
                </View>
            </Page>
            {
                equipsWithTimelines && remainingElementsChunks.map((equipsWithTimelinesChunk, pageIndex) => (
                    <Page size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }} key={pageIndex}>
                        <View style={{ border: '3px solid #000', marginTop: '2%', width: '95%', minHeight: '40px', flexDirection: 'column' }}>
                            <View style={{ width: '100%', height: '40px', display: 'flex', flexDirection: 'row' }}>
                                <View style={{ width: '16.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>EQUIPO</Text></View>
                                <View style={{ width: '14.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>MARCA, MODELO, SERIE</Text></View>
                                <View style={{ width: '13%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>UBICACIÓN</Text></View>
                                <View style={{ width: '10%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>FRECUENCIA DE MTO</Text></View>
                                <View style={{ width: '10%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>ESTADO</Text></View>
                                <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>ENE</Text></View>
                                <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>FEB</Text></View>
                                <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>MAR</Text></View>
                                <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>ABR</Text></View>
                                <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>MAY</Text></View>
                                <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>JUN</Text></View>
                                <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>JUL</Text></View>
                                <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>AGO</Text></View>
                                <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>SEP</Text></View>
                                <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>OCT</Text></View>
                                <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>NOV</Text></View>
                                <View style={{ width: '3%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}><Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>DIC</Text></View>
                            </View>
                            {equipsWithTimelinesChunk.map((d, i) => {
                                let selectedMonths = {};

                                d.timeline.forEach(t => {
                                    const increment = periodOptions.find(option => option.value === t.periodicity)?.increment || false;

                                    if (increment) {
                                        let currentMonth = moment.utc(t.month).clone();

                                        for (let j = currentMonth.month(); j < 12; j += increment) {
                                            if (currentMonth.isSameOrAfter(t.month)) {
                                                selectedMonths[months[currentMonth.month()]] = true;
                                            }
                                            currentMonth.add(increment, 'months');
                                        }
                                    }
                                });

                                return (
                                    <View key={i} style={{ width: '100%', height: '100px', flexDirection: 'row', borderTop: '3px solid #000' }}>
                                        <View style={{ width: '16.5%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}>
                                            <Text style={{ fontSize: 17 }}>{d.name}</Text>
                                        </View>
                                        <View style={{ width: '14.5%', height: '100%', borderRight: '3px solid #000' }}>
                                            <Text style={{ fontSize: 17 }}>MARCA: {d.biomedicEquipment.brand}</Text>
                                            <Text style={{ fontSize: 17 }}>MODELO: {d.biomedicEquipment.modell}</Text>
                                            <Text style={{ fontSize: 17 }}>SERIE: {d.biomedicEquipment.serie} PLACA: {d.licensePlate}</Text>
                                        </View>
                                        <View style={{ width: '13%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}>
                                            <Text style={{ fontSize: 17 }}>{d.biomedicEquipment.location}</Text>
                                        </View>
                                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}>
                                            <Text style={{ fontSize: 17 }}>{d.timeline[0].periodicity}</Text>
                                        </View>
                                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}>
                                            <Text style={{ fontSize: 17 }}>PROGRAMADO</Text>
                                        </View>
                                        {months.map((month) => (
                                            <View key={month} style={{ width: '3%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}>
                                                <Text style={{ fontSize: 17 }}>{selectedMonths[month] ? "X" : " "}</Text>
                                            </View>
                                        ))}
                                    </View>
                                );
                            })}
                        </View>
                    </Page>
                ))
            }
        </Document>
    )
}